/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PpdSupportPortalModelsApplicationUser from './PpdSupportPortalModelsApplicationUser';

/**
 * The PpdSupportPortalModelsCountry model module.
 * @module model/PpdSupportPortalModelsCountry
 * @version 1.0.0
 */
class PpdSupportPortalModelsCountry {
    /**
     * Constructs a new <code>PpdSupportPortalModelsCountry</code>.
     * @alias module:model/PpdSupportPortalModelsCountry
     */
    constructor() { 
        
        PpdSupportPortalModelsCountry.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PpdSupportPortalModelsCountry</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PpdSupportPortalModelsCountry} obj Optional instance to populate.
     * @return {module:model/PpdSupportPortalModelsCountry} The populated <code>PpdSupportPortalModelsCountry</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PpdSupportPortalModelsCountry();

            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('clinicalSupportTollFreeInstructions')) {
                obj['clinicalSupportTollFreeInstructions'] = ApiClient.convertToType(data['clinicalSupportTollFreeInstructions'], 'String');
            }
            if (data.hasOwnProperty('clinicalSupportTollFreeInfo')) {
                obj['clinicalSupportTollFreeInfo'] = ApiClient.convertToType(data['clinicalSupportTollFreeInfo'], 'String');
            }
            if (data.hasOwnProperty('clinicalSupportLocalTollInstructions')) {
                obj['clinicalSupportLocalTollInstructions'] = ApiClient.convertToType(data['clinicalSupportLocalTollInstructions'], 'String');
            }
            if (data.hasOwnProperty('patientSupportTollFreeInstructions')) {
                obj['patientSupportTollFreeInstructions'] = ApiClient.convertToType(data['patientSupportTollFreeInstructions'], 'String');
            }
            if (data.hasOwnProperty('patientSupportTollFreeInfo')) {
                obj['patientSupportTollFreeInfo'] = ApiClient.convertToType(data['patientSupportTollFreeInfo'], 'String');
            }
            if (data.hasOwnProperty('patientSupportLocalTollInstructions')) {
                obj['patientSupportLocalTollInstructions'] = ApiClient.convertToType(data['patientSupportLocalTollInstructions'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('publicId')) {
                obj['publicId'] = ApiClient.convertToType(data['publicId'], 'String');
            }
            if (data.hasOwnProperty('key')) {
                obj['key'] = ApiClient.convertToType(data['key'], 'String');
            }
            if (data.hasOwnProperty('deleted')) {
                obj['deleted'] = ApiClient.convertToType(data['deleted'], 'Boolean');
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'Number');
            }
            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = PpdSupportPortalModelsApplicationUser.constructFromObject(data['createdBy']);
            }
            if (data.hasOwnProperty('dateUpdated')) {
                obj['dateUpdated'] = ApiClient.convertToType(data['dateUpdated'], 'Date');
            }
            if (data.hasOwnProperty('updatedBy')) {
                obj['updatedBy'] = PpdSupportPortalModelsApplicationUser.constructFromObject(data['updatedBy']);
            }
            if (data.hasOwnProperty('dateDeleted')) {
                obj['dateDeleted'] = ApiClient.convertToType(data['dateDeleted'], 'Date');
            }
            if (data.hasOwnProperty('deletedBy')) {
                obj['deletedBy'] = PpdSupportPortalModelsApplicationUser.constructFromObject(data['deletedBy']);
            }
            if (data.hasOwnProperty('archived')) {
                obj['archived'] = ApiClient.convertToType(data['archived'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Boolean} active
 */
PpdSupportPortalModelsCountry.prototype['active'] = undefined;

/**
 * @member {String} name
 */
PpdSupportPortalModelsCountry.prototype['name'] = undefined;

/**
 * @member {String} clinicalSupportTollFreeInstructions
 */
PpdSupportPortalModelsCountry.prototype['clinicalSupportTollFreeInstructions'] = undefined;

/**
 * @member {String} clinicalSupportTollFreeInfo
 */
PpdSupportPortalModelsCountry.prototype['clinicalSupportTollFreeInfo'] = undefined;

/**
 * @member {String} clinicalSupportLocalTollInstructions
 */
PpdSupportPortalModelsCountry.prototype['clinicalSupportLocalTollInstructions'] = undefined;

/**
 * @member {String} patientSupportTollFreeInstructions
 */
PpdSupportPortalModelsCountry.prototype['patientSupportTollFreeInstructions'] = undefined;

/**
 * @member {String} patientSupportTollFreeInfo
 */
PpdSupportPortalModelsCountry.prototype['patientSupportTollFreeInfo'] = undefined;

/**
 * @member {String} patientSupportLocalTollInstructions
 */
PpdSupportPortalModelsCountry.prototype['patientSupportLocalTollInstructions'] = undefined;

/**
 * @member {Number} id
 */
PpdSupportPortalModelsCountry.prototype['id'] = undefined;

/**
 * @member {String} publicId
 */
PpdSupportPortalModelsCountry.prototype['publicId'] = undefined;

/**
 * @member {String} key
 */
PpdSupportPortalModelsCountry.prototype['key'] = undefined;

/**
 * @member {Boolean} deleted
 */
PpdSupportPortalModelsCountry.prototype['deleted'] = undefined;

/**
 * @member {Number} version
 */
PpdSupportPortalModelsCountry.prototype['version'] = undefined;

/**
 * @member {Date} dateCreated
 */
PpdSupportPortalModelsCountry.prototype['dateCreated'] = undefined;

/**
 * @member {module:model/PpdSupportPortalModelsApplicationUser} createdBy
 */
PpdSupportPortalModelsCountry.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateUpdated
 */
PpdSupportPortalModelsCountry.prototype['dateUpdated'] = undefined;

/**
 * @member {module:model/PpdSupportPortalModelsApplicationUser} updatedBy
 */
PpdSupportPortalModelsCountry.prototype['updatedBy'] = undefined;

/**
 * @member {Date} dateDeleted
 */
PpdSupportPortalModelsCountry.prototype['dateDeleted'] = undefined;

/**
 * @member {module:model/PpdSupportPortalModelsApplicationUser} deletedBy
 */
PpdSupportPortalModelsCountry.prototype['deletedBy'] = undefined;

/**
 * @member {Boolean} archived
 */
PpdSupportPortalModelsCountry.prototype['archived'] = undefined;






export default PpdSupportPortalModelsCountry;

