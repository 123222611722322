import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import * as ppd_support_api from "./restapi";
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ppd_support_api.ApiClient.instance.basePath = document.location.origin;

function Root() {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter basename={baseUrl}>
                <App/>
            </BrowserRouter>
        </LocalizationProvider>
    );
}

ReactDOM.render(<Root/>, rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

