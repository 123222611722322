class Globals {
    constructor() {
        const getSiteKey = () => {
            try {
                let siteKey = "clinical"; //default
                let altPaths = ['patient', 'admin'];
                let pathname = window.location.pathname;
                
                if (pathname && pathname.indexOf("/") !== -1) {
                    let pathParts = pathname.split("/");
                    if (altPaths.includes(pathParts[1])) { //if the path part if blank we default to patient which is defined above
                        siteKey = pathParts[1];
                    }
                }
                return siteKey;
            } catch (ex) {
                console.error("Can't determine site, defaulting to patient");
            }

            return "clinical";
        };

        const getCurrentPagePath = () => {
            try {
                let pathname = window.location.pathname;
                let pathParts = pathname.split("/");
             
                return pathParts.pop(); //return last part of the URL
            } catch (ex) {
                console.error("Can't determine site, defaulting to patient");
            }

            return "clinical";
        };

        this.siteKey = getSiteKey();
        this.currentPage = getCurrentPagePath();

        this.PATIENT = 'patient';
        this.CLINICAL = 'clinical';
        this.ADMIN = 'admin';
        this.COUNTRY = null;

        this.isPatient = () => {
            return this.PATIENT === this.siteKey;
        }

        this.isClinical = () => {
            return this.CLINICAL === this.siteKey;
        }
        
        this.isAdmin = () => {
            return this.ADMIN === this.siteKey;
        }
    }
}

Globals.instance = new Globals();
export default Globals;