import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Form} from "react-final-form";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {TextInput} from "../../FormComponents/TextInput";
import {StringOptional, StringRequired} from "../../helpers/validation";

export default function Chat_PatientForm(props) {

    const [initialValues, setInitialValues] = React.useState(null);
    
    const onSubmit = (data) => {

        const firstNameValue = encodeURIComponent('Anonymous');
        const callbackNumberValue = encodeURIComponent(data.callbackNumber);
        const chatLinkValue = encodeURIComponent('undefined');
        const descriptionValue = encodeURIComponent(data.description);
        const subjectNameValue = encodeURIComponent(data.subjectNumber);
        const appNameValue = encodeURIComponent(data.applicationName);
        const deviceOsValue = encodeURIComponent(data.deviceOs);
        
        const uriParams = 'chatUsername=' + firstNameValue
            + '&PARTY_PHONE=' + callbackNumberValue
            + '&chatlink=' + chatLinkValue
            + '&DESCRIPTION=' + descriptionValue
            + '&SUBJ=' + subjectNameValue
            + '&APPNAME=' + appNameValue
            + '&OS=' + deviceOsValue
            + '&USERTYPE=' + 'PATIENT'
            + '&QUEUE=' + 'Workgroup+Queue%3AITSC_General_Chat';

        window.open(props.webchatUrl + uriParams,'_blank');
    };

    const validate = (data) => {
        if (!data.description) {
            return false;
        } else {
            return data.description.trim().length > 0;
        }
    }

    /* <componentDidMount> */
    React.useEffect(() => {
        let iv = {};
        iv.description = null;
        
        setInitialValues(iv);
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    if (!initialValues) {
        return (
            <React.Fragment>
                <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                    <Box 
                        display="flex" 
                        flexDirection="row" 
                        alignItems="center" 
                        justifyContent="center"
                        className="flex-column-mobile"
                    >
                        <Typography style={{marginTop: 8}}>
                            Loading Chat
                        </Typography>
                    </Box>
                </Paper>
            </React.Fragment>
        );
    }
    
    return (
        <>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit, submitting, pristine, invalid, errors, form }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="subjectNumber"
                                        label="Subject Number"
                                        variant="outlined"
                                        autoFocus={true}
                                        //helperText="This could be a useful instruction."
                                        maxlength={50}
                                        validate={StringOptional(1, 50, "Subject Number may not exceed 50 characters.")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="applicationName"
                                        label="Application Name"
                                        variant="outlined"
                                        maxlength={50}
                                        validate={StringOptional(1, 50, "Application Name may not exceed 50 characters.")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="deviceOs"
                                        label="Device Operating System"
                                        variant="outlined"
                                        placeholder="ie. Windows, Mac OS"
                                        //helperText="This could be a useful instruction."
                                        maxlength={50}
                                        validate={StringOptional(1, 50, "Device may not exceed 50 characters.")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="callbackNumber"
                                        label="Callback Number"
                                        variant="outlined"
                                        placeholder="555-555-5555"
                                        maxlength={50}
                                        validate={StringOptional(1, 50, "Operating System may not exceed 50 characters.")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextInput
                                        name="description"
                                        required
                                        label="Short Description of Your Issue"
                                        variant="outlined"
                                        rows={4}
                                        multiline
                                        maxlength={300}
                                        validate={StringRequired(1, 300, "Short Description required. May not exceed 300 characters.")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Button 
                                        size="large" 
                                        variant="contained" 
                                        onClick={handleSubmit}
                                        endIcon={<KeyboardArrowRightIcon/>}
                                        disabled={submitting || pristine || invalid}
                                        style={{
                                            minHeight: 45,
                                            minWidth: 230,
                                            borderRadius: 0,
                                            fontWeight: 600,
                                            fontSize: 20,
                                        }}
                                    >
                                        Start Chat
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    );
                }}
            />
        </>
    );
}
