import TextField from '@material-ui/core/TextField';
import React from 'react';
import {Field} from 'react-final-form';

export const TextInput: typeof Field =
    ({ 
        name, 
        helperText, 
        validate, 
        onChange, 
        displayLabel, 
        inputLabel, 
        tooltip, 
        required, 
        maxlength,
        multiline,
        rows,
        ...rest 
    }) =>
        <Field
            name={name}
            type="text"
            validate={validate}
            render={({ input, meta }) => {
                input['maxLength'] = maxlength;
                return (
                    <React.Fragment>
                        <TextField
                            className="textfield"
                            onChange={onChange}
                            error={meta.touched && meta.error}
                            aria-errormessage={name + '_err'}
                            helperText={meta.touched && meta.error || helperText}
                            inputProps={input}
                            {...rest}
                            required={required}
                            multiline={multiline}
                            rows={rows}
                        />
                    </React.Fragment>
                );
            }}
        />
