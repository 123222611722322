/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import PpdSupportPortalModelsCountry from '../model/PpdSupportPortalModelsCountry';
import PpdSupportPortalViewModelsAdminCountriesViewModel from '../model/PpdSupportPortalViewModelsAdminCountriesViewModel';

/**
* Countries service.
* @module api/CountriesApi
* @version 1.0.0
*/
export default class CountriesApi {

    /**
    * Constructs a new CountriesApi. 
    * @alias module:api/CountriesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addCountry operation.
     * @callback module:api/CountriesApi~addCountryCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsCountry} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * AddCountry
     * @param {Object} opts Optional parameters
     * @param {module:model/PpdSupportPortalModelsCountry} opts.ppdSupportPortalModelsCountry 
     * @param {module:api/CountriesApi~addCountryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsCountry}
     */
    addCountry(opts, callback) {
      opts = opts || {};
      let postBody = opts['ppdSupportPortalModelsCountry'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsCountry;
      return this.apiClient.callApi(
        '/api/admin/countries', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteCountry operation.
     * @callback module:api/CountriesApi~deleteCountryCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsCountry} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * DeleteCountry
     * @param {String} key 
     * @param {module:api/CountriesApi~deleteCountryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsCountry}
     */
    deleteCountry(key, callback) {
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling deleteCountry");
      }

      let pathParams = {
        'key': key
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsCountry;
      return this.apiClient.callApi(
        '/api/admin/countries/{key}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAdminCountriesExport operation.
     * @callback module:api/CountriesApi~getAdminCountriesExportCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetAdminCountriesExport
     * @param {module:api/CountriesApi~getAdminCountriesExportCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    getAdminCountriesExport(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/api/admin/countries-export', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAdminCountriesViewModel operation.
     * @callback module:api/CountriesApi~getAdminCountriesViewModelCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalViewModelsAdminCountriesViewModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetAdminCountriesViewModel
     * @param {Object} opts Optional parameters
     * @param {String} opts.filter 
     * @param {String} opts.countryStatus  (default to '%')
     * @param {String} opts.orderBy  (default to 'Name')
     * @param {String} opts.order  (default to 'asc')
     * @param {Number} opts.pageSize  (default to 25)
     * @param {Number} opts.page  (default to 1)
     * @param {module:api/CountriesApi~getAdminCountriesViewModelCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalViewModelsAdminCountriesViewModel}
     */
    getAdminCountriesViewModel(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter'],
        'countryStatus': opts['countryStatus'],
        'orderBy': opts['orderBy'],
        'order': opts['order'],
        'pageSize': opts['pageSize'],
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalViewModelsAdminCountriesViewModel;
      return this.apiClient.callApi(
        '/api/admin/countries/view-model', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCountry operation.
     * @callback module:api/CountriesApi~getCountryCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsCountry} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetCountry
     * @param {String} key 
     * @param {module:api/CountriesApi~getCountryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsCountry}
     */
    getCountry(key, callback) {
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getCountry");
      }

      let pathParams = {
        'key': key
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsCountry;
      return this.apiClient.callApi(
        '/api/admin/countries/{key}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCountrySeries operation.
     * @callback module:api/CountriesApi~getCountrySeriesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/PpdSupportPortalModelsCountry>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetCountrySeries
     * @param {String} key 
     * @param {module:api/CountriesApi~getCountrySeriesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/PpdSupportPortalModelsCountry>}
     */
    getCountrySeries(key, callback) {
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getCountrySeries");
      }

      let pathParams = {
        'key': key
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [PpdSupportPortalModelsCountry];
      return this.apiClient.callApi(
        '/api/admin/country/{key}/series', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateCountry operation.
     * @callback module:api/CountriesApi~updateCountryCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsCountry} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * UpdateCountry
     * @param {String} key 
     * @param {Object} opts Optional parameters
     * @param {module:model/PpdSupportPortalModelsCountry} opts.ppdSupportPortalModelsCountry 
     * @param {module:api/CountriesApi~updateCountryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsCountry}
     */
    updateCountry(key, opts, callback) {
      opts = opts || {};
      let postBody = opts['ppdSupportPortalModelsCountry'];
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling updateCountry");
      }

      let pathParams = {
        'key': key
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsCountry;
      return this.apiClient.callApi(
        '/api/admin/countries/{key}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
