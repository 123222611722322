import React from 'react';
import Container from '@mui/material/Container';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from '@mui/material/Button';
import Ticket_PatientForm from "./Frontend/TicketComponents/Ticket_PatientForm";
import Globals from "../Globals";
import Home from "./Home";
import {Promo} from "./Promo";
import Footer from "./Footer";
import Ticket_ClinicalForm from "./Frontend/TicketComponents/Ticket_ClinicalForm";
import {Link} from "react-router-dom";

export default function Ticket(props) {

    const [settingsJson] = React.useState(props.siteData && props.siteData.settings && props.siteData.settings.appSettings);
    const [countries] = React.useState(props.siteData && props.siteData.countries);
    const [xInternalRequest] = React.useState(props.siteData && props.siteData.xInternalRequest);

    /* <componentDidMount> */
    React.useEffect(() => {
        if(props.siteData.xInternalRequest === 'True' && Globals.instance.isClinical()) {
            window.location.replace(props.siteData.cherwellHomeURL);
        }
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */
    
    if (xInternalRequest === 'True' && Globals.instance.isClinical())

        return <></>;

    return (
        <>
            <Home siteData={props.siteData} />
            <Container maxWidth="lg" className="mb50" aria-label="PPD Ticket Section">
                <div className="section-header">
                    <div className="section-back mb20">
                        <Link to={'/'} className='textLink'>
                            <Button size="large" startIcon={<KeyboardArrowLeftIcon/>}><b>Back</b></Button>
                        </Link>
                    </div>
                    <div className="label">Submit a Support <span className="green">Ticket</span></div>
                </div>
                <p className="mb50">
                    <em>
                        {Globals.instance.isPatient() && (settingsJson?.psTicketFormDisclaimer ?? '')}
                        {Globals.instance.isClinical() && (settingsJson?.csTicketFormDisclaimer ?? '')}
                    </em>
                </p>

                {Globals.instance.isClinical() ? <Ticket_ClinicalForm countries={countries} /> : <Ticket_PatientForm />}

            </Container>
            <Promo />
            <Footer />
        </>
    );
}
