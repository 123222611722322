import React, {Component} from 'react';
import {Route} from 'react-router';
import AdminNav from './AdminNav';
import Alerts from './Alerts';
import Countries from './Countries';
import Settings from './Settings';
import AdminMainMenu from './MainMenu';
import AccountMenu from './AccountMenu'
import LogoLight from '../img/PPD_logo_white-min.png';
import authService from './api-authorization/AuthorizeService';
import {Box, Button} from "@mui/material";
import {LoginMenu} from "./api-authorization/LoginMenu";

export class Admin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        return (
            <>
                {this.state.isAuthenticated ?
                    <>
                        <div className="app-header-admin">
                            <div className="branded-header">
                                <div className="header-left">
                                    <img src={LogoLight} alt="PPD - Part of Thermo Fisher Scientific" />
                                    <h2>
                                        Support<span className="green"> Admin Console</span>
                                    </h2>
                                </div>
                                <div className="user-menu">
                                    <AccountMenu/>
                                </div>
                            </div>
                        </div>
                        <div className="adminBody">
                            <AdminNav/>
                            <div className="adminContent">
                                <Route exact path='/admin' component={AdminMainMenu}/>
                                <Route path='/admin/alerts' component={Alerts}/>
                                <Route path='/admin/countries' component={Countries}/>
                                <Route path='/admin/settings' component={Settings}/>
                            </div>
                        </div>
                        <div className="branded-footer">
                            <div className="mobile-hide">© {(new Date()).getFullYear()} Pharmaceutical Product
                                Development, LLC. All rights reserved.
                            </div>
                            <div display="flex" alignItems="center" className="footer-links">
                                <div><a href="http://www.ppd.com" target="_blank">ppd.com</a></div>
                                {/*<div>|</div>*/}
                                {/*<div><a href="#" target="_blank">Help</a></div>*/}
                                {/*<div>|</div>*/}
                                {/*<div><Link to="/" underline="none">Home</Link></div>*/}
                            </div>
                        </div>
                    </> :
                    <>
                        <div className="app-header-admin">
                            <div className="branded-header">
                                <div className="header-left">
                                    <img src={LogoLight} alt="PPD - Part of Thermo Fisher Scientific" />
                                    <h2>
                                        Support<span className="green"> Admin Console</span>
                                    </h2>
                                </div>
                                <div className="user-menu">
                                    {/*<AccountMenu/>*/}
                                </div>
                            </div>
                        </div>
                        <div className="adminBody">
                            <div className="adminContent">
                                <Box
                                    sx={{
                                        marginTop: 8,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box component="form" onSubmit={() => {
                                    }} noValidate sx={{mt: 1}}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{mt: 3, mb: 2}}
                                            style={{color: 'white'}}
                                        >
                                            <LoginMenu/>
                                        </Button>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                        <div className="branded-footer">
                            <div className="mobile-hide">© {(new Date()).getFullYear()} Pharmaceutical Product
                                Development, LLC. All rights reserved.
                            </div>
                            <div display="flex" alignItems="center" className="footer-links">
                                <div><a href="http://www.ppd.com" target="_blank">ppd.com</a></div>
                                {/*<div>|</div>*/}
                                {/*<div><a href="#" target="_blank">Help</a></div>*/}
                                {/*<div>|</div>*/}
                                {/*<div><Link to="/" underline="none">Home</Link></div>*/}
                            </div>
                        </div>
                    </>
                }
            </>
        );
    }
}
