import {createTheme, ThemeProvider} from '@mui/material/styles';
import React from 'react';
import {Route} from 'react-router';
import {FetchData} from './components/FetchData';
import {Layout} from './components/Layout';
import Home from "./components/Home";
import Chat from './components/Chat';
import Call from './components/Call';
import Ticket from './components/Ticket';
import {Admin} from './components/Admin';
import Footer from './components/Footer';
import {Promo} from './components/Promo';
import './App.scss';
import './Helpers.scss';
import {ApplicationPaths} from "./components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import * as ppd_support_api from "./restapi";
import {PpdSupportPortalModelsSiteData} from "./restapi";
import moment from "moment-timezone";
import Globals from "./Globals";
import {LoadingSpinner} from "./components/helpers/LoadingSpinner";
import {Helmet} from "react-helmet";

const PpdTheme = createTheme({
    palette: {
        primary: {
            light: '#9626B7',
            main: '#5E2698',
            dark: '#9626B7'
        },
        secondary: {
            light: '#FFC720',
            main: '#FFC720',
            dark: '#FFC720'
        }
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            'Gotham',
            'Verdana',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontSize: 6000,
        },
        h2: {
            fontSize: 32,
        },
        h3: {
            fontSize: 26,
            fontWeight: 700,
        },
        h4: {
            fontSize: 20,
            fontWeight: 700,
        },
        h5: {
            fontSize: 18,
            fontWeight: 700,
        },
        subtitle1: {
            fontSize: 12,
        },
        body1: {
            fontWeight: 500,
        },
        button: {
            fontStyle: 'italic',
        }
    },
    overrides: {
        MuiDrawer: {
            root: {
                marginTop: 100,
            }
        },
        MuiBreadcrumbs: {
            root: {
                marginTop: 20,
                marginBottom: 20,
                fontSize: 13,
            },
            li: {
                fontSize: 13,
            },
        },
        MuiButton: {
            root: {
                fontStyle: 'normal',
                margin: '10px 3px',
                textTransform: 'none',
            },
        },
        MuiPaper: {
            root: {
                color: '#515151'
            }
        },
        MuiTextField: {
            root: {
                margin: '10px 0',
            }
        },
        MuiTab: {
            root: {
                fontStyle: 'normal',
                fontWeight: 700,
                textTransform: 'none',
                minWidth: '170px',
            },
            wrapper: {
                fontSize: 16
            }
        },
        MuiTableCell: {
            head: {
                color: '#515151',
                fontWeight: 700,
                fontSize: 12,
                padding: '6px 16px',
                lineHeight: 'normal',
                borderBottom: '3px solid rgba(224, 224, 224, 1)'
            }
        },
        MuiTableSortLabel: {
            root: {
                color: '#515151',
                fontWeight: 700,
                fontSize: 12,
                padding: '0px',
                lineHeight: 'normal'
            }
        },
        MuiTablePagination: {
            spacer: {
                display: 'none'
            }
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: '#F6F8FA',
                borderColor: '#DEE7ED'
            },
        },
        MuiDialogActions: {
            root: {
                padding: '10px 20px',
            }
        },
        MuiDivider: {
            root: {
                margin: '20px 0',
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                backgroundColor: '#F6F8FA',
            }
        }
    }
});

export default function App() {

    const [patientSiteData, setPatientSiteData] = React.useState(null);
    const [clinicalSiteData, setClinicalSiteData] = React.useState(null);

    const siteApi = new ppd_support_api.SitesApi();
    
    const appSettingsJsonify = (toJsonify) => {

        let s = JSON.parse(toJsonify);

        let sd = s.startDay ?? 0, 
            ed = s.endDay ?? 5;
        
        //PPDSP-162 Chat time not correctly adjusting after DST time change
        //Added local() to correctly adjust the UTC time to the local time.
        //see link for details: https://momentjs.com/docs/#/parsing/utc/
        let startTimeSplit = s.startTime.split(":"),
            endTimeSplit = s.endTime.split(":");
        
        let sundayDate = moment.tz("US/Eastern").day(sd).hour(startTimeSplit[0]).minute(startTimeSplit[1]).second(0).local(),//moment(s.startTime, 'HH:mm').utc().day(sd),
            fridayDate = moment.tz("US/Eastern").day(ed).hour(endTimeSplit[0]).minute(endTimeSplit[1]).second(0).local();//moment(s.endTime, 'HH:mm').utc().day(ed),
        
        s.startTime = sundayDate.format('dddd LT');
        s.endTime = fridayDate.format('dddd LT z');
        
        return s;
    };

    const requestPatientSiteDataCallback = (error, data: PpdSupportPortalModelsSiteData) => {
        if (error) {
            console.error(error);
        } else {
            //console.log('API call to GET:/api/patient-support-site successful.\nReturned data: ', data);

            //For the sake of convenience, we'll adapt the Settings.appSettings to JSON
            if (data.settings && data.settings.appSettings) {
                data.settings.appSettings = appSettingsJsonify(data.settings.appSettings) ;
            }

            setPatientSiteData(data);
        }
    };

    const requestClinicalSiteDataCallback = (error, data: PpdSupportPortalModelsSiteData) => {
        if (error) {
            console.error(error);
        } else {
            //console.log('API call to GET:/api/clinical-support-site successful.\nReturned data: ', data);

            //For the sake of convenience, we'll adapt the Settings.appSettings to JSON
            if (data.settings && data.settings.appSettings) {
                data.settings.appSettings = appSettingsJsonify(data.settings.appSettings) ;
            }

            setClinicalSiteData(data);
        }
    };

    /* <componentDidMount> */
    React.useEffect(() => {
        Globals.instance.isClinical() ? siteApi.clinicalSupportSite(requestClinicalSiteDataCallback) : siteApi.patientSupportSite(requestPatientSiteDataCallback);
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    if (!patientSiteData && !clinicalSiteData) { //we need to stall until the Patient or Clinical site data has been loaded.
        return <LoadingSpinner />;
    }
    
    return (
        <ThemeProvider theme={PpdTheme}>
            <Layout>
                {/*<Route exact={true} path='/' render={() => <Redirect from='/' to='/home' />}/>*/}
                <Helmet>
                    <title>
                        {Globals.instance.isAdmin() ? 'PPD Support - Admin Console' : (Globals.instance.isPatient() ? "PPD Patient Support" : "PPD Clinical Systems Support")}
                    </title>
                </Helmet>

                <Route exact path='/patient' render={() => <Home siteData={patientSiteData} />} />
                <Route exact path='/patient/chat' render={() => <Chat siteData={patientSiteData} />}/>
                <Route exact path='/patient/call' render={() => <Call siteData={patientSiteData} />}/>
                <Route exact path='/patient/ticket' render={() => <Ticket siteData={patientSiteData} />}/>

                <Route exact path='/' render={() => <Home siteData={clinicalSiteData} />} />
                <Route exact path='/chat' render={() => <Chat siteData={clinicalSiteData} />}/>
                <Route exact path='/call' render={() => <Call siteData={clinicalSiteData} />}/>
                <Route exact path='/ticket' render={() => <Ticket siteData={clinicalSiteData} />}/>
                
                <Route path="/admin" component={Admin}/>

                <Route exact path='/' component={Promo}/>
                <Route exact path='/' component={Footer}/>
                <Route exact path='/patient' component={Promo}/>
                <Route exact path='/patient' component={Footer}/>

                <AuthorizeRoute path='/fetch-data' component={FetchData} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        </ThemeProvider>
    );
}
