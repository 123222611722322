/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import PpdSupportPortalModelsAlert from '../model/PpdSupportPortalModelsAlert';
import PpdSupportPortalViewModelsAdminAlertsViewModel from '../model/PpdSupportPortalViewModelsAdminAlertsViewModel';

/**
* Alerts service.
* @module api/AlertsApi
* @version 1.0.0
*/
export default class AlertsApi {

    /**
    * Constructs a new AlertsApi. 
    * @alias module:api/AlertsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addAlert operation.
     * @callback module:api/AlertsApi~addAlertCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsAlert} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * AddAlert
     * @param {Object} opts Optional parameters
     * @param {module:model/PpdSupportPortalModelsAlert} opts.ppdSupportPortalModelsAlert 
     * @param {module:api/AlertsApi~addAlertCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsAlert}
     */
    addAlert(opts, callback) {
      opts = opts || {};
      let postBody = opts['ppdSupportPortalModelsAlert'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsAlert;
      return this.apiClient.callApi(
        '/api/admin/alert', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteAlert operation.
     * @callback module:api/AlertsApi~deleteAlertCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsAlert} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * DeleteAlert
     * @param {String} key 
     * @param {module:api/AlertsApi~deleteAlertCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsAlert}
     */
    deleteAlert(key, callback) {
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling deleteAlert");
      }

      let pathParams = {
        'key': key
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsAlert;
      return this.apiClient.callApi(
        '/api/admin/alert/{key}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAdminAlertsExport operation.
     * @callback module:api/AlertsApi~getAdminAlertsExportCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetAdminAlertsExport
     * @param {module:api/AlertsApi~getAdminAlertsExportCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    getAdminAlertsExport(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/api/admin/alerts-export', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAdminAlertsViewModel operation.
     * @callback module:api/AlertsApi~getAdminAlertsViewModelCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalViewModelsAdminAlertsViewModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetAdminAlertsViewModel
     * @param {Object} opts Optional parameters
     * @param {String} opts.filter 
     * @param {String} opts.alertStatus  (default to '%')
     * @param {String} opts.orderBy  (default to 'Title')
     * @param {String} opts.order  (default to 'asc')
     * @param {Number} opts.pageSize  (default to 25)
     * @param {Number} opts.page  (default to 1)
     * @param {module:api/AlertsApi~getAdminAlertsViewModelCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalViewModelsAdminAlertsViewModel}
     */
    getAdminAlertsViewModel(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter'],
        'alertStatus': opts['alertStatus'],
        'orderBy': opts['orderBy'],
        'order': opts['order'],
        'pageSize': opts['pageSize'],
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalViewModelsAdminAlertsViewModel;
      return this.apiClient.callApi(
        '/api/admin/alert/view-model', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAlert operation.
     * @callback module:api/AlertsApi~getAlertCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsAlert} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetAlert
     * @param {String} key 
     * @param {module:api/AlertsApi~getAlertCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsAlert}
     */
    getAlert(key, callback) {
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getAlert");
      }

      let pathParams = {
        'key': key
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsAlert;
      return this.apiClient.callApi(
        '/api/admin/alert/{key}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAlertImage operation.
     * @callback module:api/AlertsApi~getAlertImageCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetAlertImage
     * @param {String} key 
     * @param {String} image 
     * @param {module:api/AlertsApi~getAlertImageCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    getAlertImage(key, image, callback) {
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getAlertImage");
      }
      // verify the required parameter 'image' is set
      if (image === undefined || image === null) {
        throw new Error("Missing the required parameter 'image' when calling getAlertImage");
      }

      let pathParams = {
        'key': key,
        'image': image
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/api/alert/{key}/{image}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAlertSeries operation.
     * @callback module:api/AlertsApi~getAlertSeriesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/PpdSupportPortalModelsAlert>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetAlertSeries
     * @param {String} key 
     * @param {module:api/AlertsApi~getAlertSeriesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/PpdSupportPortalModelsAlert>}
     */
    getAlertSeries(key, callback) {
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getAlertSeries");
      }

      let pathParams = {
        'key': key
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [PpdSupportPortalModelsAlert];
      return this.apiClient.callApi(
        '/api/admin/alert/{key}/series', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateAlert operation.
     * @callback module:api/AlertsApi~updateAlertCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsAlert} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * UpdateAlert
     * @param {String} key 
     * @param {Object} opts Optional parameters
     * @param {module:model/PpdSupportPortalModelsAlert} opts.ppdSupportPortalModelsAlert 
     * @param {module:api/AlertsApi~updateAlertCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsAlert}
     */
    updateAlert(key, opts, callback) {
      opts = opts || {};
      let postBody = opts['ppdSupportPortalModelsAlert'];
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling updateAlert");
      }

      let pathParams = {
        'key': key
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsAlert;
      return this.apiClient.callApi(
        '/api/admin/alert/{key}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
