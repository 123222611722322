import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Form} from "react-final-form";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {TextInput} from "../../FormComponents/TextInput";
import {composeValidators, StringOptional, StringRequired} from "../../helpers/validation";

export default function Chat_ClinicalForm(props) {

    const [initialValues, setInitialValues] = React.useState(null);

    const onSubmit = (data) => {
        
        const firstNameValue = encodeURIComponent(data.firstName);
        const lastNameValue = encodeURIComponent(data.lastName);
        const emailValue = encodeURIComponent(data.emailAddress);
        const sponsorValue = encodeURIComponent(data.sponsor);
        const protocolValue = encodeURIComponent(data.protocol);
        const roleValue = encodeURIComponent(data.role);
        const callbackNumberValue = encodeURIComponent(data.callbackNumber);
        const descriptionValue = encodeURIComponent(data.description);
        const siteNumberValue = encodeURIComponent(data.siteNumber);

        const uriParams = 'chatUsername=' + firstNameValue
            + '&LAST_NAME=' + lastNameValue
            + '&EMAIL=' + emailValue
            + '&SPONSOR=' + sponsorValue
            + '&PROTOCOL=' + protocolValue
            + '&ROLE=' + roleValue
            + '&PARTY_PHONE=' + callbackNumberValue
            + '&DESCRIPTION=' + descriptionValue
            + '&SITE=' + siteNumberValue
            + '&chatlink=' + 'RDC'
            + '&USERTYPE=' + 'GENERAL' 
            + '&QUEUE=' + 'Workgroup+Queue%3AITSC_General_Chat';
        
        window.open(props.webchatUrl + uriParams,'_blank');
    };

    /* <Validators> */
    const validateEmailFormat = (msg) => (elementValue) => {
        const emailRegex = /^\S+@\S+\.\S+$/gi;
        return emailRegex.test(elementValue) ? undefined : msg;
    }

    const validate = (data) => {

        let boolArray = [];
        
        //firstName required
        if (!data.firstName) {
            return false;
        } else {
            boolArray.push(data.firstName.trim().length > 0);
        }

        //lastName required
        if (!data.lastName) {
            return false;
        } else {
            boolArray.push(data.lastName.trim().length > 0);
        }

        //emailAddress required
        if (!data.emailAddress) {
            return false;
        } else {
            boolArray.push(data.emailAddress.trim().length > 0);
        }

        //sponsor required
        if (!data.sponsor) {
            return false;
        } else {
            boolArray.push(data.sponsor.trim().length > 0);
        }

        //protocol required
        if (!data.protocol) {
            return false;
        } else {
            boolArray.push(data.protocol.trim().length > 0);
        }

        //callbackNumber required
        if (!data.callbackNumber) {
            return false;
        } else {
            boolArray.push(data.callbackNumber.trim().length > 0);
        }
        
        //Description required
        if (!data.description) {
            return false;
        } else {
            boolArray.push(data.description.trim().length > 0);
        }
        
        return boolArray.findIndex((item) => { return item === false }) > 0;
    }
    /* </Validators> */
    
    /* <componentDidMount> */
    React.useEffect(() => {
        let iv = {};
        iv.firstName = null;
        iv.lastName = null;
        iv.emailAddress = null;
        iv.sponsor = null;
        iv.protocol = null;
        iv.callbackNumber = null;
        iv.description = null;

        setInitialValues(iv);
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    if (!initialValues) {
        return (
            <React.Fragment>
                <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        className="flex-column-mobile"
                    >
                        <Typography style={{marginTop: 8}}>
                            Loading Chat
                        </Typography>
                    </Box>
                </Paper>
            </React.Fragment>
        );
    }

    return (
        <>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit, submitting, pristine, invalid, errors, form }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="firstName"
                                        label="First Name"
                                        variant="outlined"
                                        required
                                        autoFocus={true}
                                        //helperText="This could be a useful instruction."
                                        maxlength={50}
                                        validate={StringRequired(1, 50, "First Name may not exceed 50 characters.")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="lastName"
                                        label="Last Name"
                                        variant="outlined"
                                        required
                                        maxlength={50}
                                        validate={StringRequired(1, 50, "Last Name may not exceed 50 characters.")}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="emailAddress"
                                        label="Email Address"
                                        variant="outlined"
                                        required
                                        placeholder="your_email@gmail.com"
                                        //helperText="This could be a useful instruction."
                                        maxlength={100}
                                        validate={composeValidators(StringRequired(1, 100, "Email may not exceed 100 characters."), validateEmailFormat('Email format detected as invalid.'))}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="sponsor"
                                        label="Sponsor"
                                        variant="outlined"
                                        required
                                        maxlength={50}
                                        validate={StringRequired(1, 50, "Sponsor may not exceed 50 characters.")}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="protocol"
                                        label="Protocol"
                                        variant="outlined"
                                        required
                                        maxlength={50}
                                        validate={StringRequired(1, 50, "Protocol may not exceed 50 characters.")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="siteNumber"
                                        label="Site Number"
                                        variant="outlined"
                                        maxlength={50}
                                        validate={StringOptional(1, 50, "Site Number may not exceed 50 characters.")}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="role"
                                        label="Role"
                                        variant="outlined"
                                        maxlength={50}
                                        validate={StringOptional(1, 50, "Site Number may not exceed 50 characters.")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        name="callbackNumber"
                                        label="Callback Number"
                                        variant="outlined"
                                        required
                                        maxlength={50}
                                        validate={StringRequired(1, 50, "Callback Number may not exceed 50 characters.")}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} sm={12}>
                                    <TextInput
                                        name="description"
                                        required
                                        label="Short Description of Your Issue"
                                        variant="outlined"
                                        rows={4}
                                        multiline
                                        maxlength={300}
                                        validate={StringRequired(1, 300, "Short Description required. May not exceed 300 characters.")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        onClick={handleSubmit}
                                        endIcon={<KeyboardArrowRightIcon/>}
                                        disabled={submitting || pristine || invalid}
                                        style={{
                                            minHeight: 45,
                                            minWidth: 230,
                                            borderRadius: 0,
                                            fontWeight: 600,
                                            fontSize: 20,
                                        }}
                                    >
                                        Start Chat
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    );
                }}
            />
        </>
    );
}
