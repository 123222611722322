/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import PpdSupportPortalModelsSettings from '../model/PpdSupportPortalModelsSettings';

/**
* Settings service.
* @module api/SettingsApi
* @version 1.0.0
*/
export default class SettingsApi {

    /**
    * Constructs a new SettingsApi. 
    * @alias module:api/SettingsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addSettingsVersion operation.
     * @callback module:api/SettingsApi~addSettingsVersionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsSettings} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * AddSettingsVersion
     * @param {Object} opts Optional parameters
     * @param {module:model/PpdSupportPortalModelsSettings} opts.ppdSupportPortalModelsSettings 
     * @param {module:api/SettingsApi~addSettingsVersionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsSettings}
     */
    addSettingsVersion(opts, callback) {
      opts = opts || {};
      let postBody = opts['ppdSupportPortalModelsSettings'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsSettings;
      return this.apiClient.callApi(
        '/api/admin/settings', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAdminSettings operation.
     * @callback module:api/SettingsApi~getAdminSettingsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsSettings} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetAdminSettings
     * @param {module:api/SettingsApi~getAdminSettingsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsSettings}
     */
    getAdminSettings(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsSettings;
      return this.apiClient.callApi(
        '/api/admin/settings', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAdminSettingsExport operation.
     * @callback module:api/SettingsApi~getAdminSettingsExportCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetAdminSettingsExport
     * @param {module:api/SettingsApi~getAdminSettingsExportCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    getAdminSettingsExport(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/api/admin/settings-export', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAdminUserDetails operation.
     * @callback module:api/SettingsApi~getAdminUserDetailsCallback
     * @param {String} error Error message, if any.
     * @param {Boolean} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * GetAdminUserDetails
     * @param {module:api/SettingsApi~getAdminUserDetailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Boolean}
     */
    getAdminUserDetails(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/api/admin/user', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the logoutUser operation.
     * @callback module:api/SettingsApi~logoutUserCallback
     * @param {String} error Error message, if any.
     * @param {Boolean} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * LogoutUser
     * @param {module:api/SettingsApi~logoutUserCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Boolean}
     */
    logoutUser(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/api/admin/user/logout', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
