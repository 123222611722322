import React, {Component, Fragment} from 'react';
import {NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import authService from './AuthorizeService';
import {ApplicationPaths} from './ApiAuthorizationConstants';
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import * as ppd_support_api from "../../restapi/";

let settingsApi = new ppd_support_api.SettingsApi();

export class LoginMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());

        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const {isAuthenticated, userName} = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            if (!window.ppdloggedin) {
                settingsApi.getAdminUserDetails(() => {
                    window.ppdloggedin = true;
                });
            }

            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = {pathname: `${ApplicationPaths.LogOut}`, state: {local: true}};
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>*/}
            <p>{userName}</p>
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>*/}
            {/*</NavItem>*/}
            <Button variant="contained" size="small" startIcon={<LogoutIcon/>}>
                <NavLink 
                    tag={Link} 
                    style={{color: 'white'}} 
                    onClick={() => {
                        window.ppdloggedin = false;
                        settingsApi.logoutUser(() => {});//call the logout
                    }} 
                    to={logoutPath}
                >
                    Sign Out
                </NavLink>
            </Button>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={'/admin'}>Admin</NavLink>*/}
            {/*</NavItem>*/}
        </Fragment>);
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>*/}
            {/*</NavItem>*/}
            <NavLink 
                style={{color: 'white', textDecoration: 'none'}} 
                tag={Link} 
                className="text-dark"
                to={loginPath}
            >
                Login
            </NavLink>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={'/admin'}>Admin</NavLink>*/}
            {/*</NavItem>*/}
        </Fragment>);
    }
}
