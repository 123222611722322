import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Link} from "react-router-dom";
import Globals from "../../../Globals";

export default function Chat_OutsideOpenHours(props) {

    const [settingsJson] = React.useState(props.siteData && props.siteData.settings && props.siteData.settings.appSettings);

    return(
        <>
            <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="left"
                    justifyContent="left"
                    className="flex-column-mobile"
                >
                    <div className="pt20 pr10 pl10 pb5">
                        <Typography variant="h5" component="h2">
                            Chat unavailable
                        </Typography>
                    </div>
                    <div className="pt10 pr10 pl10 pb5">
                        <Typography variant="h6" component="h2">
                            {settingsJson?.chatHoursDisclaimer?.trim() ?? 'Our chat specialists are unavailable at this time. Please try again during business hours:'}
                        </Typography>
                    </div>
                    <div className="pt10 pr10 pl10 pb5">
                        <Typography variant="h6" component="h2">
                            {settingsJson?.startTime ?? 'N/A'} – {settingsJson?.endTime ?? 'N/A'}
                        </Typography>
                    </div>
                    <Divider className="align-content-center pt10 pr10 pl10 pb5" />
                    <div className="pt20 pr10 pl10 pb5">
                        <Link to={`${Globals.instance.isPatient() ? '/patient/' : '/'}ticket`}  className="textLink">
                            Submit a Support Ticket<KeyboardArrowRightIcon fontSize="small"/>
                        </Link>
                    </div>
                </Box>
            </Paper>
        </>
    );

}
