import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import React from "react";
import {Form} from 'react-final-form';
import {PpdSupportPortalModelsCountry} from "../../../restapi";
import {TextInput} from "../../FormComponents/TextInput";
import {StringOptional, StringRequired} from "../../helpers/validation";
import {SelectInput} from "../../FormComponents/SelectInput";
import FormControl from "@mui/material/FormControl";
import Deletion_AreYouSure from "../../helpers/Deletion_AreYouSure";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const activeDataSet = [
    {
        value: true,
        label: 'Active',
    },
    {
        value: false,
        label: 'Inactive',
    },
];

const generateDropdownOptions = (dataSet): any[] => {
    let rtn = [];

    //rtn.push(<MenuItem key={0} value={0}>{'-- EITHER --'}</MenuItem>);

    Array.from(dataSet).forEach((option) => {
        rtn.push(<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>);
    });

    return rtn;
}

export default function Countries_Module_AddEdit(props) {

    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const onSubmit = (alertData):PpdSupportPortalModelsCountry => {
        props.handleSubmit(alertData);
    }

    const validate = (data):PpdSupportPortalModelsCountry => {

        let errors = {};
        
        let cssLTExists = data.clinicalSupportLocalTollInstructions?.trim().length > 0;
        let psLTExists = data.patientSupportLocalTollInstructions?.trim().length > 0;

        //Method without the tags
        // const findIntlPhoneRegex = /[-\s.]?[\d\s]{8,}/g;
        // const replaceNonDigitRegex = /[\D]+/gi;
        // const findPinRegex = /PIN [\d]+/gi;
        // const replacePinRegex = /[Pp][Ii][Nn] /g;

        //Method with the tags
        let phoneRegex = new RegExp(/(href="tel:\+)(.*?)(">)/, 'gi');
        let pinRegex = new RegExp(/(<b>)(.*?)(<)/, 'gi');

        if(cssLTExists && psLTExists) {
            let cslti = data.clinicalSupportLocalTollInstructions, 
                pslti = data.patientSupportLocalTollInstructions;
            
            // let cssPhone = data.clinicalSupportLocalTollInstructions.match(findIntlPhoneRegex);
            // let trimCssPhone = cssPhone && cssPhone.length === 1 ? cssPhone[0].replace(replaceNonDigitRegex, "") : null;
            // let psPhone = data.patientSupportLocalTollInstructions.match(findIntlPhoneRegex);
            // let trimPsPhone = psPhone && psPhone.length === 1 ? psPhone[0].replace(replaceNonDigitRegex, "") : null;
            // let cssPin = data.clinicalSupportLocalTollInstructions.match(findPinRegex);
            // let trimCssPin = cssPin && cssPin.length === 1 ? cssPin[0].replace(replacePinRegex, '') : null;
            // let psPin = data.patientSupportLocalTollInstructions.match(findPinRegex);
            // let trimPsPin = psPin && psPin.length === 1 ? psPin[0].replace(replacePinRegex, '') : null;

            phoneRegex = new RegExp(/(href="tel:\+)(.*?)(">)/, 'gi'); //Need to reset the pointer
            pinRegex = new RegExp(/(<b>)(.*?)(<)/, 'gi'); //Need to reset the pointer

            let cssPhone = phoneRegex.exec(cslti);
            let trimCssPhone = cssPhone && cssPhone.length >= 3 ? cssPhone[2].replace(/\D/g,'') : null;

            let cssPin = pinRegex.exec(cslti);
            let trimCssPin = cssPin && cssPin.length >= 3 ? cssPin[2] : null;

            phoneRegex = new RegExp(/(href="tel:\+)(.*?)(">)/, 'gi'); //Need to reset the pointer
            pinRegex = new RegExp(/(<b>)(.*?)(<)/, 'gi'); //Need to reset the pointer

            let psPhone = phoneRegex.exec(pslti);
            let trimPsPhone = psPhone && psPhone.length >= 3 ? psPhone[2].replace(/\D/g,'') : null;

            let psPin = pinRegex.exec(pslti);
            let trimPsPin = psPin && psPin.length >= 3 ? psPin[2] : null;
            
            if (trimCssPhone === trimPsPhone) { //if the Phone#s match, keep checking
                if (trimCssPin && trimPsPin) { //if there is a PIN (ie. Neither is NULL), keep checking
                    if (trimCssPin === trimPsPin) { //if PINS match, throw validation
                        errors.clinicalSupportLocalTollInstructions = 'Clinical and Patient Local Toll Phone# + PIN may not be the same';
                        errors.patientSupportLocalTollInstructions = 'Clinical and Patient Local Toll Phone# + PIN may not be the same'; 
                        
                        return errors;
                    }
                }
            }
        }

        let cssTSExists = data.clinicalSupportTollFreeInstructions?.trim().length > 0;
        let psLTSExists = data.patientSupportTollFreeInstructions?.trim().length > 0;
        if(cssTSExists && psLTSExists) {

            let cstfi = data.clinicalSupportTollFreeInstructions, 
                pstfi = data.patientSupportTollFreeInstructions;

            // let cssPhone = data.clinicalSupportTollFreeInstructions.match(findIntlPhoneRegex);
            // let trimCssPhone = cssPhone && cssPhone.length === 1 ? cssPhone[0].replace(replaceNonDigitRegex, "") : null;
            // let psPhone = data.patientSupportTollFreeInstructions.match(findIntlPhoneRegex);
            // let trimPsPhone = psPhone && psPhone.length === 1 ? psPhone[0].replace(replaceNonDigitRegex, "") : null;
            // let cssPin = data.clinicalSupportTollFreeInstructions.match(findPinRegex);
            // let trimCssPin = cssPin && cssPin.length === 1 ? cssPin[0].replace(replacePinRegex, '') : null;
            // let psPin = data.patientSupportTollFreeInstructions.match(findPinRegex);
            // let trimPsPin = psPin && psPin.length === 1 ? psPin[0].replace(replacePinRegex, '') : null;

            phoneRegex = new RegExp(/(href="tel:\+)(.*?)(">)/, 'gi'); //Need to reset the pointer
            pinRegex = new RegExp(/(<b>)(.*?)(<)/, 'gi'); //Need to reset the pointer

            let cssPhone = phoneRegex.exec(cstfi);
            let trimCssPhone = cssPhone && cssPhone.length >= 3 ? cssPhone[2].replace(/\D/g,'') : null;

            let cssPin = pinRegex.exec(cstfi);
            let trimCssPin = cssPin && cssPin.length >= 3 ? cssPin[2] : null;

            phoneRegex = new RegExp(/(href="tel:\+)(.*?)(">)/, 'gi'); //Need to reset the pointer
            pinRegex = new RegExp(/(<b>)(.*?)(<)/, 'gi'); //Need to reset the pointer

            let psPhone = phoneRegex.exec(pstfi);
            let trimPsPhone = psPhone && psPhone.length >= 3 ? psPhone[2].replace(/\D/g,'') : null;

            let psPin = pinRegex.exec(pstfi);
            let trimPsPin = psPin && psPin.length >= 3 ? psPin[2] : null;

            if (trimCssPhone === trimPsPhone) { //if the Phone#s match, keep checking
                if (trimCssPin && trimPsPin) { //if there is a PIN (ie. Neither is NULL), keep checking
                    if (trimCssPin === trimPsPin) { //if PINS match, throw validation
                        errors.clinicalSupportTollFreeInstructions = 'Clinical and Patient Local Toll Phone# + PIN may not be the same';
                        errors.patientSupportTollFreeInstructions = 'Clinical and Patient Local Toll Phone# + PIN may not be the same';

                        return errors;
                    }
                }
            }
        }

        return undefined;
    }

    return (
        <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={props.countryToEdit || { active: true }}
            render={({ handleSubmit, submitting, pristine, invalid, errors, form }) => {
                return (
                    <Modal
                        open={props.open}
                        onClose={props.handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className="modal-form medium">
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    <Typography id="modal-modal-title-not-upper" variant="h6" component="h2">
                                        Country Details
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton onClick={props.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <Grid container spacing={3} className="pt20">
                                    <Grid item xs={12} sm={12} md={3}>
                                        <TextInput
                                            name="name"
                                            label="Country Name"
                                            variant="outlined"
                                            required
                                            maxlength={255}
                                            validate={StringRequired(1, 255, "Country Name required. May not exceed 255 characters.")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <SelectInput
                                            name="active"
                                            variant="outlined"
                                            fullWidth={true}
                                            shrink={true}
                                            displayLabel="Country Status"
                                            options={generateDropdownOptions(activeDataSet)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} />
                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className="form-section green">
                                            <div className="form-section-header">Clinical Systems Support</div>
                                            <div className="form-section-body">
                                                <Stack direction="column" alignItems="center" spacing={2}>
                                                    <FormControl fullWidth>
                                                        <TextInput
                                                            name="clinicalSupportTollFreeInfo"
                                                            label="Comments"
                                                            variant="outlined"
                                                            rows={3}
                                                            multiline
                                                            maxlength={255}
                                                            validate={StringOptional(1, 255, "Comments may not exceed 255 characters.")}
                                                            style={{ backgroundColor: 'white' }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <TextInput
                                                            name="clinicalSupportTollFreeInstructions"
                                                            label="Toll Free Instruction"
                                                            variant="outlined"
                                                            rows={3}
                                                            multiline
                                                            maxlength={255}
                                                            validate={StringOptional(1, 255, "Toll Free Instruction may not exceed 255 characters.")}
                                                            style={{ backgroundColor: 'white' }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <TextInput
                                                            name="clinicalSupportLocalTollInstructions"
                                                            label="Local Instruction"
                                                            variant="outlined"
                                                            rows={3}
                                                            multiline
                                                            maxlength={255}
                                                            validate={StringOptional(1, 255, "Local Instruction may not exceed 255 characters.")}
                                                            style={{ backgroundColor: 'white' }}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className="form-section">
                                            <div className="form-section-header">Patient Support</div>
                                            <div className="form-section-body">
                                                <Stack direction="column" alignItems="center" spacing={2}>
                                                    <FormControl fullWidth>
                                                        <TextInput
                                                            name="patientSupportTollFreeInfo"
                                                            label="Comments"
                                                            variant="outlined"
                                                            rows={3}
                                                            multiline
                                                            maxlength={255}
                                                            validate={StringOptional(1, 255, "Comments may not exceed 255 characters.")}
                                                            style={{ backgroundColor: 'white' }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <TextInput
                                                            name="patientSupportTollFreeInstructions"
                                                            label="Toll Free Instruction"
                                                            variant="outlined"
                                                            rows={3}
                                                            multiline
                                                            maxlength={255}
                                                            validate={StringOptional(1, 255, "Toll Free Instruction may not exceed 255 characters.")}
                                                            style={{ backgroundColor: 'white' }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <TextInput
                                                            name="patientSupportLocalTollInstructions"
                                                            label="Local Instruction"
                                                            variant="outlined"
                                                            rows={3}
                                                            multiline
                                                            maxlength={255}
                                                            validate={StringOptional(1, 255, "Local Instruction may not exceed 255 characters.")}
                                                            style={{ backgroundColor: 'white' }}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </div>
                                        </div>
                                    </Grid>
                                    {/*{errors && <div className="error-box"><Typography className="error">{errors}</Typography></div>}*/}
                                    <Grid item xs={12} sm={12}>
                                        <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end">
                                            <Button size="large" variant="outlined" onClick={() => {setDeleteOpen(true)}}>Delete</Button>
                                            <Button
                                                size="large"
                                                variant="outlined"
                                                onClick={() => {
                                                    props.handleClose();
                                                    form.reset();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button size="large" variant="contained" onClick={handleSubmit} disabled={submitting || pristine || invalid}>Save</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                            <Deletion_AreYouSure
                                open={deleteOpen}
                                handleClose={() => {
                                    setDeleteOpen(false);
                                }}
                                handleConfirm={() => {
                                    props.handleDelete();
                                    setDeleteOpen(false);
                                }}
                            />
                        </Box>
                    </Modal>
                );
            }}
        />
    );
}