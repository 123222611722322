import React from 'react';
import {Link} from 'react-router-dom';
import Paper from '@mui/material/Paper';
import authService from "./api-authorization/AuthorizeService";
import moment from "moment";

export default function MainMenu() {

    const handleExport = async () => {

        const token = await authService.getAccessToken();

        fetch("/api/admin/events-export", {
            "headers": {
                "authorization": `Bearer ${token}`,
                "cache-control": "no-cache",
                "pragma": "no-cache",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-origin"
            },
            "referrer": window.location.pathname,
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": null,
            "method": "GET",
            "mode": "cors",
            "credentials": "include",
        }).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                let generatedFileName = "ActivityLog_" + moment().format("YYYY-MM-DD").toString() + ".csv"
                a.href = url;
                a.download = generatedFileName;
                a.click();
            });
            //window.location.href = response.url;
        });
    };
    
    return (
        <>
            <div className="page-header">
                <h1>Main Menu</h1>
            </div>
            <Paper className="p20">
                <ul className="main-menu-links">
                    <li>
                        <Link to="admin/alerts">Alerts</Link> - Manage alert messages displayed on the Clinical Systems Support and Patient Support home pages.
                    </li>
                    <li>
                        <Link to="admin/countries">Countries</Link> - Manage Country contact information.
                    </li>
                    <li>
                        <Link to="admin/settings">Settings</Link> - Manage portal application configuration settings.
                    </li>
                    <li>
                        <Link to="#" onClick={handleExport}>Activity Log</Link> - Export a log file with user activity over the last 90 days.
                    </li>
                </ul>
            </Paper>
        </>
    );
}
        