import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Field, Form} from "react-final-form";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {TextInput} from "../../FormComponents/TextInput";
import {composeValidators, StringRequired} from "../../helpers/validation";
import {FormControl, RadioGroup} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import {SelectInput} from "../../FormComponents/SelectInput";
import * as ppd_support_api from "../../../restapi";
import {PpdSupportPortalModelsCountry} from "../../../restapi";
import {CircularProgress, FormLabel} from "@mui/material";
import Divider from "@mui/material/Divider";
import {Link} from "react-router-dom";
import ppdSupportPortalModelsClinicalSupportTicket from "../../../restapi/model/PpdSupportPortalModelsClinicalSupportTicket";

const availabilityOptions = [
    {
        value: 'Morning',
        label: 'Morning',
    },
    {
        value: 'Afternoon',
        label: 'Afternoon',
    },
    {
        value: 'Evening',
        label: 'Evening',
    },
    {
        value: 'Night',
        label: 'Night',
    },
];

const roleOptions = [
    {
        value: 'Site',
        label: 'Site',
    },
    {
        value: 'CRA',
        label: 'CRA',
    },
    {
        value: 'Sponsor',
        label: 'Sponsor',
    },
    {
        value: 'Other',
        label: 'Other',
    },
];

const systemOptions = [
    { value: 'Aperio', label: 'Aperio'},
    { value: 'eAPI', label: 'eAPI'},
    { value: 'ePIP', label: 'ePIP'},
    { value: 'FSP Source', label: 'FSP Source'},
    { value: 'goBalto', label: 'goBalto'},
    { value: 'InForm', label: 'InForm'},
    { value: 'Medidata', label: 'Medidata'},
    { value: 'Mobile App', label: 'Mobile App'},
    { value: 'PPD Data Portal', label: 'PPD Data Portal'},
    { value: 'PPD MDM', label: 'PPD MDM'},
    { value: 'PPD\'s eTMF', label: 'PPD\'s eTMF'},
    { value: 'Preclarus and PPD Dashboards', label: 'Preclarus and PPD Dashboards'},
    { value: 'Preclarus® Investigator Site Portal (LABS)', label: 'Preclarus® Investigator Site Portal (LABS)'},
    { value: 'Sequence WebEAS', label: 'Sequence WebEAS'},
];

const generateDropdownOptions = (dataSet, includeBlank) => {
    let rtn = [];

    if (includeBlank) {
        rtn.push(<MenuItem key={0} value={0}>{'--'}</MenuItem>);
    }
        
    Array.from(dataSet).forEach((option) => {
        rtn.push(<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>);
    });

    return rtn;
}

const generateCountriesDropdownOptions = (dataSet): any[] => {
    let rtn = [];

    rtn.push(<MenuItem key="call_country_default_key" value={0}>--Select a Country--</MenuItem>)

    if (dataSet?.length > 0) {
        Array.from(dataSet).forEach((option: PpdSupportPortalModelsCountry) => {
            rtn.push(<MenuItem key={option.key} value={option.name}>{option.name}</MenuItem>);
        });
    } else {
        rtn.push(<MenuItem key="call_country_default_key" value={-1}>No Countries Detected</MenuItem>)
    }

    return rtn;
}

export default function Ticket_ClinicalForm(props) {

    let ticketApi = new ppd_support_api.TicketsApi();

    const [initialValues, setInitialValues] = React.useState(null);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [busObPublicId, setBusObPublicId] = React.useState(null);
    const [countriesList, setCountriesList] = React.useState(null);
    
    const trans_reqs = [{ display: "Yes", value: "yes" }, { display: "No", value: "no" }];

    /* <Functions> */
    const onSubmit = (data) => {
        setShowSpinner(true);

        const POST_Callback = (error, bopId) => {
            if (error) {
                console.error(error);
            } else {
                setShowSpinner(false);
                setBusObPublicId(bopId);
            }
        };
        
        const opts = { ppdSupportPortalModelsClinicalSupportTicket: data };
        
        ticketApi.clinicalSupportTicket(opts, POST_Callback);
    };
    /* </Functions> */

    /* <Validators> */

    const validateSelect = (msg) => (value) => {
        return value !== 0 ? undefined : msg;
    }

    const validateEmailFormat = (msg) => (elementValue) => {
        const emailRegex = /^\S+@\S+\.\S+$/gi;
        return emailRegex.test(elementValue) ? undefined : msg;
    }

    const validate = (data) => {
        
        let errors = {};
        
        if (!data.requireTranslation) {
            errors.requireTranslation = "Required Translation is required.";
        }

        return errors;
    }
    /* <Validators> */

    /* <componentDidMount> */
    React.useEffect(() => {
        let iv = new ppdSupportPortalModelsClinicalSupportTicket();
        
        iv.system = 0;
        iv.firstName = null;
        iv.lastName = null;
        iv.emailAddress = null;
        iv.country = 0;
        iv.callbackNumber = null;
        iv.availability = 0;
        iv.requireTranslation = null; //trans_reqs[1].value;
        iv.userId = null;
        iv.sponsor = null;
        iv.protocol = null;
        iv.siteNumber = null;
        iv.role = 0; //roleOptions[0].value;
        iv.comment = null;
        
        setInitialValues(iv);
        
        setCountriesList(props.countries);
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    if (!initialValues) {
        return (
            <React.Fragment>
                <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        className="flex-column-mobile"
                    >
                        <Typography style={{marginTop: 8}}>
                            Loading Ticket Form
                        </Typography>
                    </Box>
                </Paper>
            </React.Fragment>
        );
    }

    if (!busObPublicId && showSpinner) {
        return (
            <>
                <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        className="flex-column-mobile"
                    >
                        <CircularProgress />
                    </Box>
                </Paper>
            </>
        );
    }
    
    if (!busObPublicId) {
        return (
            <>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={initialValues}
                    render={({ handleSubmit, submitting, pristine, invalid, errors, form }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <SelectInput
                                            name="system"
                                            variant="outlined"
                                            fullWidth={true}
                                            shrink={true}
                                            autoFocus={true}
                                            displayLabel="Please select your system *"
                                            options={generateDropdownOptions(systemOptions, true)}
                                            validate={validateSelect('Please select a System')}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="firstName"
                                            label="First Name"
                                            variant="outlined"
                                            required
                                            //helperText="This could be a useful instruction."
                                            maxlength={50}
                                            validate={StringRequired(1, 50, "First Name may not exceed 50 characters.")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="lastName"
                                            label="Last Name"
                                            variant="outlined"
                                            required
                                            maxlength={50}
                                            validate={StringRequired(1, 50, "Last Name may not exceed 50 characters.")}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="emailAddress"
                                            label="Email Address"
                                            variant="outlined"
                                            required
                                            placeholder="your_email@gmail.com"
                                            //helperText="This could be a useful instruction."
                                            maxlength={100}
                                            validate={composeValidators(StringRequired(1, 100, "Email may not exceed 100 characters."), validateEmailFormat('Email format detected as invalid.'))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="callbackNumber"
                                            label="Callback Number"
                                            variant="outlined"
                                            required
                                            maxlength={50}
                                            validate={StringRequired(1, 50, "Callback Number may not exceed 50 characters.")}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12} md={3}>
                                        <SelectInput
                                            name="country"
                                            variant="outlined"
                                            fullWidth={true}
                                            shrink={true}
                                            displayLabel="Please select your country *"
                                            options={generateCountriesDropdownOptions(countriesList)}
                                            defaultValue={0}
                                            validate={validateSelect('Please select a Country')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <SelectInput
                                            name="role"
                                            variant="outlined"
                                            fullWidth={true}
                                            shrink={true}
                                            displayLabel="Please select your role *"
                                            options={generateDropdownOptions(roleOptions, true)}
                                            validate={validateSelect('Please select a Role')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <SelectInput
                                            name="availability"
                                            variant="outlined"
                                            fullWidth={true}
                                            shrink={true}
                                            displayLabel="Availability *"
                                            options={generateDropdownOptions(availabilityOptions, true)}
                                            validate={validateSelect('Please select a Time of Day')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Do you require translation? *</FormLabel>
                                            <RadioGroup row aria-label="contact" name="row-radio-buttons-group" className="row-radio-buttons-group">
                                                <label>
                                                    <Field
                                                        name="requireTranslation"
                                                        component="input"
                                                        type="radio"
                                                        value={trans_reqs[0].value}
                                                    />{' '}
                                                    {trans_reqs[0].display}
                                                </label>
                                                <label style={{ marginLeft: 15 }}>
                                                    <Field
                                                        name="requireTranslation"
                                                        component="input"
                                                        type="radio"
                                                        value={trans_reqs[1].value}
                                                    />{' '}
                                                    {trans_reqs[1].display}
                                                </label>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="sponsor"
                                            label="Sponsor"
                                            variant="outlined"
                                            required
                                            maxlength={50}
                                            validate={StringRequired(1, 50, "Sponsor may not exceed 50 characters.")}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="userId"
                                            label="User ID"
                                            variant="outlined"
                                            required
                                            maxlength={50}
                                            validate={StringRequired(1, 50, "User ID may not exceed 50 characters.")}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="protocol"
                                            label="Protocol"
                                            variant="outlined"
                                            required
                                            maxlength={50}
                                            validate={StringRequired(1, 50, "Protocol may not exceed 50 characters.")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="siteNumber"
                                            label="Site Number"
                                            variant="outlined"
                                            required
                                            maxlength={50}
                                            validate={StringRequired(1, 50, "Site Number may not exceed 50 characters.")}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <TextInput
                                            name="comment"
                                            required
                                            label="Short Description of Your Issue"
                                            variant="outlined"
                                            rows={4}
                                            multiline
                                            maxlength={300}
                                            validate={StringRequired(1, 300, "Short Description required. May not exceed 300 characters.")}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            onClick={handleSubmit}
                                            endIcon={<KeyboardArrowRightIcon/>}
                                            disabled={submitting || pristine || invalid}
                                            style={{
                                                minHeight: 45,
                                                minWidth: 230,
                                                borderRadius: 0,
                                                fontWeight: 600,
                                                fontSize: 20,
                                            }}
                                        >
                                            Submit a Ticket
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </>
        );
    }

    return(
        <>
            <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    alignItems="left" 
                    justifyContent="left"
                    className="flex-column-mobile"
                >
                    <div className="pt20 pr10 pl10 pb5">
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Your Ticket (#{busObPublicId}) was submitted.
                        </Typography>
                    </div>
                    <div className="pt10 pr10 pl10 pb5">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Please retain this ticket number for your records.
                        </Typography>
                    </div>
                    <div className="pt10 pr10 pl10 pb5">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            If this is urgent, we recommend contacting us over phone or chat. Thank you!
                        </Typography>
                    </div>
                    <Divider className="align-content-center pt10 pr10 pl10 pb5" />
                    <div className="pt20 pr10 pl10 pb5">
                        <Link to="#" className="textLink" onClick={() => setBusObPublicId(null)}>
                            Submit a new Ticket<KeyboardArrowRightIcon fontSize="small"/>
                        </Link>
                    </div>
                </Box>
            </Paper>
        </>
    );
    
}
