/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PpdSupportPortalModelsApplicationUser model module.
 * @module model/PpdSupportPortalModelsApplicationUser
 * @version 1.0.0
 */
class PpdSupportPortalModelsApplicationUser {
    /**
     * Constructs a new <code>PpdSupportPortalModelsApplicationUser</code>.
     * @alias module:model/PpdSupportPortalModelsApplicationUser
     */
    constructor() { 
        
        PpdSupportPortalModelsApplicationUser.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PpdSupportPortalModelsApplicationUser</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PpdSupportPortalModelsApplicationUser} obj Optional instance to populate.
     * @return {module:model/PpdSupportPortalModelsApplicationUser} The populated <code>PpdSupportPortalModelsApplicationUser</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PpdSupportPortalModelsApplicationUser();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('userName')) {
                obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
            }
            if (data.hasOwnProperty('normalizedUserName')) {
                obj['normalizedUserName'] = ApiClient.convertToType(data['normalizedUserName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('normalizedEmail')) {
                obj['normalizedEmail'] = ApiClient.convertToType(data['normalizedEmail'], 'String');
            }
            if (data.hasOwnProperty('emailConfirmed')) {
                obj['emailConfirmed'] = ApiClient.convertToType(data['emailConfirmed'], 'Boolean');
            }
            if (data.hasOwnProperty('passwordHash')) {
                obj['passwordHash'] = ApiClient.convertToType(data['passwordHash'], 'String');
            }
            if (data.hasOwnProperty('securityStamp')) {
                obj['securityStamp'] = ApiClient.convertToType(data['securityStamp'], 'String');
            }
            if (data.hasOwnProperty('concurrencyStamp')) {
                obj['concurrencyStamp'] = ApiClient.convertToType(data['concurrencyStamp'], 'String');
            }
            if (data.hasOwnProperty('phoneNumber')) {
                obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
            }
            if (data.hasOwnProperty('phoneNumberConfirmed')) {
                obj['phoneNumberConfirmed'] = ApiClient.convertToType(data['phoneNumberConfirmed'], 'Boolean');
            }
            if (data.hasOwnProperty('twoFactorEnabled')) {
                obj['twoFactorEnabled'] = ApiClient.convertToType(data['twoFactorEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('lockoutEnd')) {
                obj['lockoutEnd'] = ApiClient.convertToType(data['lockoutEnd'], 'Date');
            }
            if (data.hasOwnProperty('lockoutEnabled')) {
                obj['lockoutEnabled'] = ApiClient.convertToType(data['lockoutEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('accessFailedCount')) {
                obj['accessFailedCount'] = ApiClient.convertToType(data['accessFailedCount'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} id
 */
PpdSupportPortalModelsApplicationUser.prototype['id'] = undefined;

/**
 * @member {String} userName
 */
PpdSupportPortalModelsApplicationUser.prototype['userName'] = undefined;

/**
 * @member {String} normalizedUserName
 */
PpdSupportPortalModelsApplicationUser.prototype['normalizedUserName'] = undefined;

/**
 * @member {String} email
 */
PpdSupportPortalModelsApplicationUser.prototype['email'] = undefined;

/**
 * @member {String} normalizedEmail
 */
PpdSupportPortalModelsApplicationUser.prototype['normalizedEmail'] = undefined;

/**
 * @member {Boolean} emailConfirmed
 */
PpdSupportPortalModelsApplicationUser.prototype['emailConfirmed'] = undefined;

/**
 * @member {String} passwordHash
 */
PpdSupportPortalModelsApplicationUser.prototype['passwordHash'] = undefined;

/**
 * @member {String} securityStamp
 */
PpdSupportPortalModelsApplicationUser.prototype['securityStamp'] = undefined;

/**
 * @member {String} concurrencyStamp
 */
PpdSupportPortalModelsApplicationUser.prototype['concurrencyStamp'] = undefined;

/**
 * @member {String} phoneNumber
 */
PpdSupportPortalModelsApplicationUser.prototype['phoneNumber'] = undefined;

/**
 * @member {Boolean} phoneNumberConfirmed
 */
PpdSupportPortalModelsApplicationUser.prototype['phoneNumberConfirmed'] = undefined;

/**
 * @member {Boolean} twoFactorEnabled
 */
PpdSupportPortalModelsApplicationUser.prototype['twoFactorEnabled'] = undefined;

/**
 * @member {Date} lockoutEnd
 */
PpdSupportPortalModelsApplicationUser.prototype['lockoutEnd'] = undefined;

/**
 * @member {Boolean} lockoutEnabled
 */
PpdSupportPortalModelsApplicationUser.prototype['lockoutEnabled'] = undefined;

/**
 * @member {Number} accessFailedCount
 */
PpdSupportPortalModelsApplicationUser.prototype['accessFailedCount'] = undefined;






export default PpdSupportPortalModelsApplicationUser;

