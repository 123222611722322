import React from "react";
import Grid from "@mui/material/Grid";
import {Link, useLocation, withRouter} from "react-router-dom";
import call from "../../../img/PPD_Call_icon-min.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import chat from "../../../img/PPD_Chat_icon-min.png";
import submit from "../../../img/PPD_Ticket_icon-min.png";
import Container from "@mui/material/Container";
import Globals from "../../../Globals";

const HomeTabs = ({ history }) => {

    let location = useLocation();
    
    const [value, setValue] = React.useState(null);
    
    React.useEffect(
        () => {
            let splitPath = location.pathname.split('/');
            setValue(splitPath[splitPath.length - 1]);
        },
        [location]
    );
    
    return (
        <>
            <Container className="support-tabs" maxWidth="lg">
                <div className={`text-center ${(Globals.instance.currentPage === '' || Globals.instance.currentPage === 'patient') ? '' : 'mobile-hide' }`} aria-label="PPD Tabs Section">
                    <Grid container spacing={3} className="p20">
                        <Grid item xs={12} sm={12} md={4} className={`tab ${value === 'call' ? 'active' : ''}`}>
                            <Link
                                to={`${Globals.instance.isPatient() ? '/patient/' : '/'}call`} 
                                underline="none" 
                                className="tab-link" 
                            >
                                <img src={call} alt="Call Our Support Team"/>
                                <h2>Call Our Support Team</h2>
                                {/*<p>Nullam malesuada iaculis arcu, et dictum est scelerisque placerat.</p>*/}
                                <KeyboardArrowDownIcon fontSize="small"/>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} className={`tab ${value === 'chat' ? 'active' : ''}`}>
                            <Link 
                                to={`${Globals.instance.isPatient() ? '/patient/' : '/'}chat`}
                                underline="none" 
                                className="tab-link" 
                            >
                                <img src={chat} alt="Chat With Support"/>
                                <h2>Chat With a Specialist</h2>
                                {/*<p>Nullam malesuada iaculis arcu, et dictum est scelerisque placerat.</p>*/}
                                <KeyboardArrowDownIcon fontSize="small"/>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} className={`tab ${value === 'ticket' ? 'active' : ''}`}>
                            <Link 
                                to={`${Globals.instance.isPatient() ? '/patient/' : '/'}ticket`} 
                                underline="none" 
                                className="tab-link"
                            >
                                <img src={submit} alt="Submit a Ticket"/>
                                <h2>Submit a Ticket</h2>
                                {/*<p>Nullam malesuada iaculis arcu, et dictum est scelerisque placerat.</p>*/}
                                <KeyboardArrowDownIcon fontSize="small"/>
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </>
    );
}

export default withRouter(HomeTabs);