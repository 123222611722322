import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function Deletion_AreYouSure(props) {

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="deletion-confirmation-dialog-title"
            aria-describedby="deletion-confirmation-privacy-dialog-desc"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <Typography variant="h5" component="h2">
                            Are You Sure?
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={props.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography>
                            You will no longer be able to make changes to this item.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button size="large" variant="outlined" onClick={props.handleClose}>Cancel</Button>
                <Button size="large" variant="contained" color="primary" onClick={props.handleConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}