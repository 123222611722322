/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import PpdSupportPortalModelsClinicalSupportTicket from '../model/PpdSupportPortalModelsClinicalSupportTicket';
import PpdSupportPortalModelsPatientSupportTicket from '../model/PpdSupportPortalModelsPatientSupportTicket';

/**
* Tickets service.
* @module api/TicketsApi
* @version 1.0.0
*/
export default class TicketsApi {

    /**
    * Constructs a new TicketsApi. 
    * @alias module:api/TicketsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the clinicalSupportTicket operation.
     * @callback module:api/TicketsApi~clinicalSupportTicketCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * ClinicalSupportTicket
     * @param {Object} opts Optional parameters
     * @param {module:model/PpdSupportPortalModelsClinicalSupportTicket} opts.ppdSupportPortalModelsClinicalSupportTicket 
     * @param {module:api/TicketsApi~clinicalSupportTicketCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    clinicalSupportTicket(opts, callback) {
      opts = opts || {};
      let postBody = opts['ppdSupportPortalModelsClinicalSupportTicket'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/clinical-support-ticket', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the patientSupportTicket operation.
     * @callback module:api/TicketsApi~patientSupportTicketCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * PatientSupportTicket
     * @param {Object} opts Optional parameters
     * @param {module:model/PpdSupportPortalModelsPatientSupportTicket} opts.ppdSupportPortalModelsPatientSupportTicket 
     * @param {module:api/TicketsApi~patientSupportTicketCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    patientSupportTicket(opts, callback) {
      opts = opts || {};
      let postBody = opts['ppdSupportPortalModelsPatientSupportTicket'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/patient-support-ticket', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
