import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React, {Component} from "react";
import moment from 'moment';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export default class Home_AlertsList_LearnMore extends Component {

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="dialog-modal-title"
                aria-describedby="dialog-modal-description"
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            {(this.props.alertToView?.title && this.props.alertToView?.title.length > 70) ?
                                <LightTooltip
                                    placement="top"
                                    interactive
                                    title={this.props.alertToView?.title}
                                >
                                    <Typography variant="h4" component="h2">
                                        {this.props.alertToView?.title.substring(0, 70) + "..."}
                                    </Typography>
                                </LightTooltip>:
                                <Typography variant="h4" component="h2">
                                    {this.props.alertToView?.title}
                                </Typography>
                            }
                        </Box>
                        <Box>
                            <IconButton onClick={this.props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h6" component="h6">
                                {this.props.alertToView?.description}
                            </Typography>
                        </Grid>
                        {this.props.alertToView?.image && this.props.alertToView?.image.length > 0 &&
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div align="center">
                                        <img
                                            src={"/api/alert/" + this.props.alertToView?.key + "/" + this.props.alertToView?.image}
                                            className="alerts-image"
                                            alt={this.props.alertToView?.title}
                                        />
                                    </div>
                                </Grid>
                            </>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="outlined" onClick={this.props.handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }
}