import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Countries_Module_AddEdit from "./Admin/Countries/Countries_Module_AddEdit";
import * as ppd_support_api from "../restapi";
import isGuid from "./helpers/validation";
import _ from "lodash";
import PpdSupportPortalModelsCountry from "../restapi/model/PpdSupportPortalModelsCountry";
import authService from "./api-authorization/AuthorizeService";
import moment from "moment";
import {Nav} from "reactstrap";

const columns = [
    {
        id: 'name', 
        label: 'Name', 
        minWidth: 300,
        className: 'table-title',
    },
    {
        id: 'active',
        label: 'Status',
        minWidth: 300,
        align: 'left',
        format: (value) => {
            return value ? 'Active' : 'Inactive'
        },
    },
];

export default function Countries() {

    const [open, setOpen] = React.useState(false);
    const [countriesViewModel, setCountriesViewModel] = React.useState(null);
    const [rParams, setRParams] = React.useState({});
    const [countryToEdit, setCountryToEdit] = React.useState(null);

    let api = new ppd_support_api.CountriesApi();
    let params = {
        countryStatus: 10, //default to Active
    };

    /* <Functions> */
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = (data: PpdSupportPortalModelsCountry) => {
        const POST_PUT_Callback = (error) => {
            if (error) {
                console.error(error);
            } else {
                handleClose();
                api.getAdminCountriesViewModel(rParams, requestVMCallback);
            }
        };
        const opts = { ppdSupportPortalModelsCountry: data };

        if (!data.key) {
            //ADD
            api.addCountry(opts, POST_PUT_Callback);
        } else {
            //UPDATE
            api.updateCountry(data.key, opts, POST_PUT_Callback);
        }
    };

    const handleExport = async () => {

        const token = await authService.getAccessToken();
        
        fetch("/api/admin/countries-export", {
            "headers": {
                "authorization": `Bearer ${token}`,
                "cache-control": "no-cache",
                "pragma": "no-cache",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-origin"
            },
            "referrer": window.location.pathname,
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": null,
            "method": "GET",
            "mode": "cors",
            "credentials": "include"
        }).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                let generatedFileName = "countries_" + moment().format("YYYY-MM-DD").toString() + ".csv"
                a.href = url;
                a.download = generatedFileName;
                a.click();
            });
            //window.location.href = response.url;
        });
    };

    const handleDelete = () => {
        const DELETE_Callback = (error, data) => {
            if (error) {
                console.error(error);
            } else {
                handleClose();
                api.getAdminCountriesViewModel(params, requestVMCallback);
            }
        };

        if (countryToEdit.key) {
            api.deleteCountry(countryToEdit.key, DELETE_Callback);
        }
    }

    const requestVMCallback = (error, data) => {
        if (error) {
            console.error(error);
        } else {
            //console.log('API call to GET:/api/admin/country/view-model successful.\nReturned data: ', data);
            setCountriesViewModel(data);
            setCountryToEdit(null);
        }
    };

    const requestTargetCountryCallback = (error, data) => {
        if (error) {
            console.error(error);
        } else {
            //console.log('API call to GET:/api/admin/country/{key:guid} successful.\nReturned data: ', data);
            setCountryToEdit(data);
        }
    };

    const handleModuleOpen = (key) => {
        if (isGuid(key)) {
            api.getCountry(key, requestTargetCountryCallback);
        } else {
            setCountryToEdit(null);
        }

        handleOpen();
    };

    const handleChangeStatus = (event) => {
        params = rParams;
        params.countryStatus = event.target.value;
        params.page = 1;
        setRParams(params);
        api.getAdminCountriesViewModel(params, requestVMCallback);
    };

    const handleChangePage = (event, newPage) => {
        params = rParams;
        params.page = newPage + 1;
        setRParams(params);
        api.getAdminCountriesViewModel(params, requestVMCallback);
    };

    const handleChangeRowsPerPage = (event) => {
        params = rParams;
        params.pageSize = (parseInt(event.target.value, 10));
        params.page = 1;
        setRParams(params);
        api.getAdminCountriesViewModel(params, requestVMCallback);
    };

    const loadCountriesViewModelDebounced = _.debounce(() => {
        api.getAdminCountriesViewModel(params, requestVMCallback);
    }, 500);

    const handleChangeSearch = (event) => {
        params = rParams;
        params.filter = event.target.value;
        params.page = 1;
        setRParams(params);
        loadCountriesViewModelDebounced();
    };
    /* </Functions> */

    /* <componentDidMount> */
    React.useEffect(() => {
        api.getAdminCountriesViewModel(params, requestVMCallback);
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    /* <Data check> */
    if (!countriesViewModel) {
        return (
            <React.Fragment>
                <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                    <Box 
                        display="flex" 
                        flexDirection="row" 
                        alignItems="center" 
                        justifyContent="center"
                        className="flex-column-mobile"
                    >
                        <Typography style={{marginTop: 8}}>
                            Loading Countries
                        </Typography>
                    </Box>
                </Paper>
            </React.Fragment>
        );
    }
    /* </Data check> */

    return (
        <>
            <div className="page-header">
                <Countries_Module_AddEdit
                    open={open}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    handleDelete={handleDelete}
                    countryToEdit={countryToEdit}
                />
                <h1>Countries</h1>
                <div className="page-header-actions">
                    <Button
                        size="large"
                        variant="text"
                        className="mr10"
                        startIcon={<FileDownloadIcon/>}
                        onClick={handleExport}
                    >
                        Export Countries History
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        startIcon={<ControlPointIcon/>}
                        onClick={handleModuleOpen}
                    >
                        Add Country
                    </Button>
                </div>
            </div>
            <Paper sx={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
                <div className="table-header-actions">
                    <div className="search">
                        <SearchIcon className="icon"/>
                        <InputBase
                            placeholder="Search…"
                            inputProps={{'aria-label': 'search', maxLength: 50}}
                            onChange={handleChangeSearch}
                        />
                    </div>
                    <Box sx={{minWidth: 120}}>
                        <FormControl fullWidth>
                            <InputLabel id="">Status</InputLabel>
                            <Select
                                labelId="countryStatusFilter"
                                id="countryStatusFilter"
                                label="Status"
                                onChange={handleChangeStatus}
                                defaultValue={10}
                            >
                                <MenuItem value={'%'}>All</MenuItem>
                                <MenuItem value={10}>Active</MenuItem>
                                <MenuItem value={20}>Inactive</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <div className="admin-table">
                    <TableContainer sx={{maxHeight: '100%'}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth, fontWeight: "bold"}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {countriesViewModel && countriesViewModel.countries.map((row: PpdSupportPortalModelsCountry) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.publicId}
                                            onClick={() => handleModuleOpen(row.key)}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} className={column.className}>
                                                        {column.format ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <TablePagination
                    //rowsPerPageOptions={[0]}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={countriesViewModel.countTotal}
                    rowsPerPage={countriesViewModel.pageSize}
                    page={countriesViewModel.page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

