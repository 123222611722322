/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import PpdSupportPortalModelsSiteData from '../model/PpdSupportPortalModelsSiteData';

/**
* Sites service.
* @module api/SitesApi
* @version 1.0.0
*/
export default class SitesApi {

    /**
    * Constructs a new SitesApi. 
    * @alias module:api/SitesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the clinicalSupportSite operation.
     * @callback module:api/SitesApi~clinicalSupportSiteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsSiteData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * ClinicalSupportSite
     * @param {module:api/SitesApi~clinicalSupportSiteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsSiteData}
     */
    clinicalSupportSite(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsSiteData;
      return this.apiClient.callApi(
        '/api/clinical-support-site', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the patientSupportSite operation.
     * @callback module:api/SitesApi~patientSupportSiteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PpdSupportPortalModelsSiteData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * PatientSupportSite
     * @param {module:api/SitesApi~patientSupportSiteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PpdSupportPortalModelsSiteData}
     */
    patientSupportSite(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PpdSupportPortalModelsSiteData;
      return this.apiClient.callApi(
        '/api/patient-support-site', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
