import React from 'react';
import Container from '@mui/material/Container';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from '@mui/material/Button';
import Home from "./Home";
import {Promo} from "./Promo";
import Footer from "./Footer";
import Call_Form from "./Frontend/CallComponents/Call_Form";
import {Link} from 'react-router-dom';

export default function Call(props) {

    const [settingsJson] = React.useState(props.siteData && props.siteData.settings && props.siteData.settings.appSettings);

    return (
        <>
            <Home siteData={props.siteData} />
            <Container maxWidth="lg">
                <div className="section-header">
                    <div className="section-back mb20">
                        <Link to={'/'} className='textLink'>
                            <Button size="large" startIcon={<KeyboardArrowLeftIcon/>}><b>Back</b></Button>
                        </Link>
                    </div>
                    <div className="label">
                        <span className="green">Call</span> Our Support Team
                    </div>
                    <div className="hours">{settingsJson?.startTime ?? 'N/A'} – {settingsJson?.endTime ?? 'N/A'}</div>
                </div>
                <p className="mb50">
                    <em>
                        
                    </em>
                </p>

                <Call_Form siteData={props.siteData} />

            </Container>
            <Promo />
            <Footer />
        </>
    );
}
