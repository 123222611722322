import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function PatientPrivacyPolicy(props) {
    
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="patient-privacy-dialog-title"
            aria-describedby="patient-privacy-dialog-desc"
            maxWidth={'lg'}
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <Typography variant="h5" component="h2">
                            Privacy Note for Technical Support
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={props.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography>
                            During the clinical trial you may use devices (such as tablets) or applications to collect information about your participation in the study.  It is possible you will need technical support if you have any problems with, or questions about, the use of this technology.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography>
                            This note explains what personal information is collected about you by technical support representatives and what steps are taken to ensure your information is treated confidentially and in compliance with data protection laws.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography>
                            To protect your privacy, in your communication with the technical support representative you will not normally be required to share any information that directly identifies you as an individual, such as your given or family names, national insurance or social security number, or home address.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography>
                            When contacting technical support over the phone or via live webchat, the technical support representative may ask you for other information, such as the name of the application(s) you use in the clinical trial, the name or the sponsor of the clinical trial, your non-identifiable clinical trial subject number, the device type or operating system, or other general or technical information that may be required for the purpose of providing technical support to you and troubleshooting the problem you have. In certain circumstances, particularly to assist you with password reset, we may ask you for your email address in order to send you a temporary password to access your account. Your email will only be stored temporarily in any technical support service systems so we can support your request.  Your call with the technical support representative may be monitored by another technical support representative for quality assurance purposes. Your call will not be recorded.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography>
                            If you contact technical support outside of business hours, you will have the option to leave a voicemail and request a call back. In this situation you will need to provide a telephone number and some means by which we can refer to you when the representative calls you back (ideally your clinical trial subject number or your first name if you wish). The voicemail recording you leave will be stored in our systems temporarily and will be deleted after 72 hours.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography>
                            The technical support representative may ask that you grant permission for a temporary remote session and control of your device. During the remote session, it is recommended that to protect your privacy, you save any work in progress, exit any applications and close any open documents displaying content that may contain private and confidential information. During the remote session, we will not access or copy any files stored locally on your device. We will record that the remote session has taken place and its duration, but will not collect your IP address, physical address, device name, or any other technical information regarding the device. You will have the option to discontinue the remote session at any time. We cannot take responsibility for any changes that may be made to the configuration of your system or device settings.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography>
                            For more information on how your personal information is handled in your clinical trial, your data protection rights and who to contact with any questions, please refer to the confidentiality section of your Informed Consent Form.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button size="large" variant="outlined" onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}