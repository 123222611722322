/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PpdSupportPortalModelsApplicationUser from './PpdSupportPortalModelsApplicationUser';

/**
 * The PpdSupportPortalModelsAlert model module.
 * @module model/PpdSupportPortalModelsAlert
 * @version 1.0.0
 */
class PpdSupportPortalModelsAlert {
    /**
     * Constructs a new <code>PpdSupportPortalModelsAlert</code>.
     * @alias module:model/PpdSupportPortalModelsAlert
     * @param display {String} 
     * @param title {String} 
     * @param description {String} 
     */
    constructor(display, title, description) { 
        
        PpdSupportPortalModelsAlert.initialize(this, display, title, description);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, display, title, description) { 
        obj['display'] = display;
        obj['title'] = title;
        obj['description'] = description;
    }

    /**
     * Constructs a <code>PpdSupportPortalModelsAlert</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PpdSupportPortalModelsAlert} obj Optional instance to populate.
     * @return {module:model/PpdSupportPortalModelsAlert} The populated <code>PpdSupportPortalModelsAlert</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PpdSupportPortalModelsAlert();

            if (data.hasOwnProperty('display')) {
                obj['display'] = ApiClient.convertToType(data['display'], 'String');
            }
            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = ApiClient.convertToType(data['image'], 'String');
            }
            if (data.hasOwnProperty('imageBase64')) {
                obj['imageBase64'] = ApiClient.convertToType(data['imageBase64'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('publicId')) {
                obj['publicId'] = ApiClient.convertToType(data['publicId'], 'String');
            }
            if (data.hasOwnProperty('key')) {
                obj['key'] = ApiClient.convertToType(data['key'], 'String');
            }
            if (data.hasOwnProperty('deleted')) {
                obj['deleted'] = ApiClient.convertToType(data['deleted'], 'Boolean');
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'Number');
            }
            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = PpdSupportPortalModelsApplicationUser.constructFromObject(data['createdBy']);
            }
            if (data.hasOwnProperty('dateUpdated')) {
                obj['dateUpdated'] = ApiClient.convertToType(data['dateUpdated'], 'Date');
            }
            if (data.hasOwnProperty('updatedBy')) {
                obj['updatedBy'] = PpdSupportPortalModelsApplicationUser.constructFromObject(data['updatedBy']);
            }
            if (data.hasOwnProperty('dateDeleted')) {
                obj['dateDeleted'] = ApiClient.convertToType(data['dateDeleted'], 'Date');
            }
            if (data.hasOwnProperty('deletedBy')) {
                obj['deletedBy'] = PpdSupportPortalModelsApplicationUser.constructFromObject(data['deletedBy']);
            }
            if (data.hasOwnProperty('archived')) {
                obj['archived'] = ApiClient.convertToType(data['archived'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} display
 */
PpdSupportPortalModelsAlert.prototype['display'] = undefined;

/**
 * @member {Boolean} active
 */
PpdSupportPortalModelsAlert.prototype['active'] = undefined;

/**
 * @member {Date} startDate
 */
PpdSupportPortalModelsAlert.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
PpdSupportPortalModelsAlert.prototype['endDate'] = undefined;

/**
 * @member {String} title
 */
PpdSupportPortalModelsAlert.prototype['title'] = undefined;

/**
 * @member {String} description
 */
PpdSupportPortalModelsAlert.prototype['description'] = undefined;

/**
 * @member {String} image
 */
PpdSupportPortalModelsAlert.prototype['image'] = undefined;

/**
 * @member {String} imageBase64
 */
PpdSupportPortalModelsAlert.prototype['imageBase64'] = undefined;

/**
 * @member {Number} id
 */
PpdSupportPortalModelsAlert.prototype['id'] = undefined;

/**
 * @member {String} publicId
 */
PpdSupportPortalModelsAlert.prototype['publicId'] = undefined;

/**
 * @member {String} key
 */
PpdSupportPortalModelsAlert.prototype['key'] = undefined;

/**
 * @member {Boolean} deleted
 */
PpdSupportPortalModelsAlert.prototype['deleted'] = undefined;

/**
 * @member {Number} version
 */
PpdSupportPortalModelsAlert.prototype['version'] = undefined;

/**
 * @member {Date} dateCreated
 */
PpdSupportPortalModelsAlert.prototype['dateCreated'] = undefined;

/**
 * @member {module:model/PpdSupportPortalModelsApplicationUser} createdBy
 */
PpdSupportPortalModelsAlert.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateUpdated
 */
PpdSupportPortalModelsAlert.prototype['dateUpdated'] = undefined;

/**
 * @member {module:model/PpdSupportPortalModelsApplicationUser} updatedBy
 */
PpdSupportPortalModelsAlert.prototype['updatedBy'] = undefined;

/**
 * @member {Date} dateDeleted
 */
PpdSupportPortalModelsAlert.prototype['dateDeleted'] = undefined;

/**
 * @member {module:model/PpdSupportPortalModelsApplicationUser} deletedBy
 */
PpdSupportPortalModelsAlert.prototype['deletedBy'] = undefined;

/**
 * @member {Boolean} archived
 */
PpdSupportPortalModelsAlert.prototype['archived'] = undefined;






export default PpdSupportPortalModelsAlert;

