import React from "react";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import {SelectInput} from "../../FormComponents/SelectInput";
import {PpdSupportPortalModelsCountry} from "../../../restapi";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Form} from 'react-final-form';
import Globals from "../../../Globals";

const generateDropdownOptions = (dataSet): any[] => {
    let rtn = [];

    rtn.push(<MenuItem key="call_country_default_key" value={0}>--Select a Country--</MenuItem>)

    if (dataSet?.length > 0) {
        Array.from(dataSet).forEach((option: PpdSupportPortalModelsCountry) => {
            rtn.push(<MenuItem key={option.key} value={option.key}>{option.name}</MenuItem>);
        });
    } else {
        rtn.push(<MenuItem key="call_country_default_key" value={-1}>No Countries Detected</MenuItem>)
    }

    return rtn;
}

export default function Call_Form(props) {

    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const [countriesList] = React.useState(props.siteData && props.siteData.countries);

    /* <Functions> */
    const handleCountrySelection = (obj) => {
        let countryKey = obj.target.value;

        if (countryKey && countryKey !== -1) {
            let targetCountry = countriesList.find((c) => {
                return c.key === countryKey;
            });

            setSelectedCountry(targetCountry);
        }
    };
    /* </Functions> */

    const setCountryByName = (name) => {

        Globals.instance.COUNTRY = name;

        if (name) {
            let targetCountry = countriesList.find((c) => {
                return c.name === name || c.name.indexOf(name) != -1;
            });

            setSelectedCountry(targetCountry);
        }
    };

    /* <componentDidMount> */
    React.useEffect(() => {
        let initialCountry = new PpdSupportPortalModelsCountry();
        initialCountry.key = 0;
        setSelectedCountry(initialCountry);

        try {
            //Lets try and get our location and set the value of the country.
            if (!Globals.instance.COUNTRY) {
                const get_ip = async () => {
                    const response = await fetch("https://geolocation-db.com/json/").catch((e) => {
                        console.log('Error: Fetch calling https://geolocation-db.com/json/ failed. ' + e.toString());
                    });

                    let data;
                    if (response) {
                        data = await response.json();
                    }

                    setCountryByName(data && data.country_name);
                };
                
                get_ip();
            } else {
                setCountryByName(Globals.instance.COUNTRY);
            }
        } catch (e) {
            console.error("Could not auto detect location: ", e);
        }


    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    /* <Data check> */
    if (!countriesList) {
        return (
            <React.Fragment>
                <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center"
                         className="flex-column-mobile">
                        <Typography style={{marginTop: 8}}>
                            Loading Call Instructions
                        </Typography>
                    </Box>
                </Paper>
            </React.Fragment>
        );
    }
    /* </Data check> */

    return (
        <>
            <Grid container spacing={6} className="pt30">
                <Grid item xs={12} sm={12} md={8} className="">
                    <FormGroup>
                        <Form
                            onSubmit={handleCountrySelection}
                            initialValues={selectedCountry}
                            render={() => {
                                return (
                                    <SelectInput
                                        name="key"
                                        variant="outlined"
                                        fullWidth={true}
                                        shrink={true}
                                        autoFocus={true}
                                        displayLabel="Please select your country"
                                        options={generateDropdownOptions(countriesList)}
                                        onChange={handleCountrySelection}
                                        defaultValue={0}
                                    />
                                );
                            }}
                        />
                        <div className="results">
                            <Grid container spacing={3} className="pt30">
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="contact-info">
                                        <div>Toll Free:</div>
                                        {/*<div>*/}
                                        {/*    {selectedCountry && Globals.instance.isClinical() && (selectedCountry.clinicalSupportTollFreeInstructions ?? '')}*/}
                                        {/*    {selectedCountry && Globals.instance.isPatient() && (selectedCountry.patientSupportTollFreeInstructions ?? '')}*/}
                                        {/*</div>*/}

                                        {selectedCountry
                                            && Globals.instance.isClinical()
                                            && (<div
                                                className="content"
                                                dangerouslySetInnerHTML={{__html: selectedCountry && selectedCountry.clinicalSupportTollFreeInstructions}}
                                            /> ?? '')
                                        }
                                        {selectedCountry
                                            && Globals.instance.isPatient()
                                            && (<div
                                                className="content"
                                                dangerouslySetInnerHTML={{__html: selectedCountry && selectedCountry.patientSupportTollFreeInstructions}}
                                            /> ?? '')
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="contact-info">
                                        <div>Local:</div>
                                        {/*<div>*/}
                                        {/*    {selectedCountry && Globals.instance.isClinical() && (selectedCountry.clinicalSupportLocalTollInstructions ?? '')}*/}
                                        {/*    {selectedCountry && Globals.instance.isPatient() && (selectedCountry.patientSupportLocalTollInstructions ?? '')}*/}
                                        {/*</div>*/}
                                        {selectedCountry
                                            && Globals.instance.isClinical()
                                            && (<div
                                                className="content"
                                                dangerouslySetInnerHTML={{__html: selectedCountry && selectedCountry.clinicalSupportLocalTollInstructions}}
                                            /> ?? '')
                                        }
                                        {selectedCountry
                                            && Globals.instance.isPatient()
                                            && (<div
                                                className="content"
                                                dangerouslySetInnerHTML={{__html: selectedCountry && selectedCountry.patientSupportLocalTollInstructions}}
                                            /> ?? '')
                                        }
                                    </div>
                                </Grid>
                                {selectedCountry && Globals.instance.isClinical() && selectedCountry.clinicalSupportTollFreeInfo?.length > 0 &&
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div>
                                            {selectedCountry && Globals.instance.isClinical() && (selectedCountry.clinicalSupportTollFreeInfo ?? '')}
                                        </div>
                                    </Grid>
                                }
                                {selectedCountry && Globals.instance.isPatient() && selectedCountry.patientSupportTollFreeInfo?.length > 0 &&
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div>
                                            {selectedCountry && Globals.instance.isPatient() && (selectedCountry.patientSupportTollFreeInfo ?? '')}
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                            <hr/>
                            <p className="small"><em>NOTE: Charges may apply, based on your plan</em></p>
                        </div>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className="">
                    <div className="phoneContact">
                        <div className="header">Global Phone Numbers</div>
                        <div className="body">
                            <ul>
                                <li>United States:<br/><span><a href="tel:+18667650279">1 (866) 765-0279</a></span></li>
                                <li>United Kingdom:<br/><span><a href="tel:+441223374580">+44 1223 374580</a></span></li>
                            </ul>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
