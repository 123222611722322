import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Field, Form} from "react-final-form";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {TextInput} from "../../FormComponents/TextInput";
import {StringOptional, StringRequired} from "../../helpers/validation";
import {FormControl, RadioGroup} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import {SelectInput} from "../../FormComponents/SelectInput";
import * as ppd_support_api from "../../../restapi";
import {CircularProgress, FormLabel} from "@mui/material";
import PpdSupportPortalModelsPatientSupportTicket from "../../../restapi/model/PpdSupportPortalModelsPatientSupportTicket";
import Divider from "@mui/material/Divider";
import {Link} from "react-router-dom";
import {isString} from "lodash";

const availabilityOptions = [
    {
        value: 'Morning',
        label: 'Morning',
    },
    {
        value: 'Afternoon',
        label: 'Afternoon',
    },
    {
        value: 'Evening',
        label: 'Evening',
    },
    {
        value: 'Night',
        label: 'Night',
    },
];

const generateDropdownOptions = (dataSet): any[] => {
    let rtn = [];

    rtn.push(<MenuItem key={0} value={0}>{'--'}</MenuItem>);

    Array.from(dataSet).forEach((option) => {
        rtn.push(<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>);
    });

    return rtn;
}

export default function Ticket_PatientForm() {

    let ticketApi = new ppd_support_api.TicketsApi();

    //Constants
    const contact_prefs = [{ display: "By Phone", value: "Phone" }, { display: "By Email", value: "Email" }];
    const trans_reqs = [{ display: "Yes", value: "yes" }, { display: "No", value: "no" }];

    //State Variables
    const [initialValues, setInitialValues] = React.useState(null);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [busObPublicId, setBusObPublicId] = React.useState(null);
    const [preferEmailOrPhone, setPreferEmailOrPhone] = React.useState(null);
    
    /* <Functions> */
    const onSubmit = (data) => {
        setShowSpinner(true);
        
        const POST_Callback = (error, responseInfo) => {
            if (error) {
                console.error(error);
            } else {
                setShowSpinner(false);
                setBusObPublicId(responseInfo);
            }
        };

        //EmailAddress is a required field but it not be the preferred contact method
        data.callbackNumber = data.callbackNumber ?? 'N/A';
        data.emailAddress = data.emailAddress ?? 'N/A';
        data.contactPreference = preferEmailOrPhone;
        
        const opts = { ppdSupportPortalModelsPatientSupportTicket: data };

        ticketApi.patientSupportTicket(opts, POST_Callback);
    };

    const validateSelect = (msg) => (value) => {
        return value !== 0 ? undefined : msg;
    }
    
    const validateEmailFormat = (elementValue) => {
        const emailRegex = /^\S+@\S+\.\S+$/gi;
        return emailRegex.test(elementValue);
    }
    
    const validate = (data) => {
        
        let errors = {};
        
        if (!preferEmailOrPhone) {
            errors.contactPreference = 'Contact Preference is required.';
        }

        if (!data.requireTranslation) {
            errors.requireTranslation = 'Required Translation is required.';
        }
        
        if (preferEmailOrPhone === contact_prefs[0].value) {
            if (!(isString(data.callbackNumber) && data.callbackNumber.length >= 1 && data.callbackNumber.length <= 50)) {
                errors.callbackNumber = 'Callback Number may not exceed 50 characters.';
            }
        }
        
        if (preferEmailOrPhone === contact_prefs[1].value) {
            if (!(isString(data.emailAddress) && data.emailAddress.length >= 1 && data.emailAddress.length <= 100)) {
                errors.emailAddress = 'Email Address may not exceed 100 characters.';
            } else if (!validateEmailFormat(data.emailAddress)) {
                errors.emailAddress = 'Email format detected as invalid.';
            }
        }
        
        return errors;
    }
    /* </Functions> */
    
    /* <componentDidMount> */
    React.useEffect(() => {
        let iv = new PpdSupportPortalModelsPatientSupportTicket();
        
        iv.availability = 0;
        // iv.contactPreference = contact_prefs[0].value;
        // iv.requireTranslation = trans_reqs[1].value;
        
        setInitialValues(iv);
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    /* <DataCheck> */
    if (!initialValues) {
        return (
            <React.Fragment>
                <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        className="flex-column-mobile"
                    >
                        <Typography style={{marginTop: 8}}>
                            Loading Ticket Form
                        </Typography>
                    </Box>
                </Paper>
            </React.Fragment>
        );
    }

    if (!busObPublicId && showSpinner) {
        return (
            <>
                <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        className="flex-column-mobile"
                    >
                        <CircularProgress />
                    </Box>
                </Paper>
            </>
        );
    }

    if (!busObPublicId) {
        return (
            <>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={initialValues}
                    render={({ handleSubmit, submitting, pristine, invalid, errors, form }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <Grid container spacing={3}>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">How do you prefer to be contacted? *</FormLabel>
                                            <RadioGroup row aria-label="contact" name="row-radio-buttons-group" className="row-radio-buttons-group">
                                                <label>
                                                    <Field
                                                        name="contactPreference"
                                                        component="input"
                                                        type="radio"
                                                        value={contact_prefs[0].value}
                                                        checked={preferEmailOrPhone === contact_prefs[0].value}
                                                        onChange={(event) => {
                                                            form.change('emailAddress', undefined);
                                                            setPreferEmailOrPhone(event.target.value);
                                                        }}
                                                    />{' '}
                                                    {contact_prefs[0].display}
                                                </label>
                                                <label style={{ marginLeft: 15 }}>
                                                    <Field
                                                        name="contactPreference"
                                                        component="input"
                                                        type="radio"
                                                        value={contact_prefs[1].value}
                                                        checked={preferEmailOrPhone === contact_prefs[1].value}
                                                        onChange={(event) => {
                                                            form.change('callbackNumber', undefined);
                                                            setPreferEmailOrPhone(event.target.value);
                                                        }}
                                                    />{' '}
                                                    {contact_prefs[1].display}
                                                </label>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} />
                                    
                                    <Grid item xs={12} sm={12} md={6}>
                                        {(preferEmailOrPhone === contact_prefs[1].value) &&
                                            <TextInput
                                                name="emailAddress"
                                                label="Email Address"
                                                variant="outlined"
                                                required
                                                maxlength={100}
                                            />
                                        }
                                        {(preferEmailOrPhone === contact_prefs[0].value) &&
                                            <TextInput
                                                name="callbackNumber"
                                                label="Callback Number"
                                                variant="outlined"
                                                required
                                                maxlength={50}
                                            />
                                        }
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="subjectNumber"
                                            label="Subject Number"
                                            variant="outlined"
                                            autoFocus={true}
                                            //helperText="This could be a useful instruction."
                                            maxlength={50}
                                            validate={StringOptional(1, 50, "Subject Number may not exceed 50 characters.")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SelectInput
                                            name="availability"
                                            variant="outlined"
                                            fullWidth={true}
                                            shrink={true}
                                            displayLabel="Availability *"
                                            options={generateDropdownOptions(availabilityOptions)}
                                            validate={validateSelect('Please select a Time of Day')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Do you require translation? *</FormLabel>
                                            <RadioGroup row aria-label="contact" name="row-radio-buttons-group" className="row-radio-buttons-group">
                                                <label>
                                                    <Field
                                                        name="requireTranslation"
                                                        component="input"
                                                        type="radio"
                                                        value={trans_reqs[0].value}
                                                    />{' '}
                                                    {trans_reqs[0].display}
                                                </label>
                                                <label style={{ marginLeft: 15 }}>
                                                    <Field
                                                        name="requireTranslation"
                                                        component="input"
                                                        type="radio"
                                                        value={trans_reqs[1].value}
                                                    />{' '}
                                                    {trans_reqs[1].display}
                                                </label>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="applicationName"
                                            label="Application Name"
                                            variant="outlined"
                                            maxlength={50}
                                            validate={StringOptional(1, 50, "Application Name may not exceed 50 characters.")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            name="deviceOs"
                                            label="Device Operating System"
                                            variant="outlined"
                                            placeholder="Android/iOS"
                                            // helperText="This could be a useful instruction."
                                            maxlength={50}
                                            validate={StringOptional(1, 50, "Operating System may not exceed 50 characters.")}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12}>
                                        <TextInput
                                            name="comment"
                                            label="Short Description of Your Issue"
                                            variant="outlined"
                                            placeholder="Short Description"
                                            required
                                            // helperText="This could be a useful instruction."
                                            maxlength={300}
                                            validate={StringRequired(1, 300, "Short Description may not exceed 300 characters.")}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12}>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            onClick={handleSubmit}
                                            endIcon={<KeyboardArrowRightIcon/>}
                                            disabled={submitting || pristine || invalid}
                                            style={{
                                                minHeight: 45,
                                                minWidth: 230,
                                                borderRadius: 0,
                                                fontWeight: 600,
                                                fontSize: 20,
                                            }}
                                        >
                                            Submit a Ticket
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </>
        );
    }

    return(
        <>
            <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    alignItems="left" 
                    justifyContent="left"
                    className="flex-column-mobile"
                >
                    <div className="pt20 pr10 pl10 pb5">
                        <Typography variant="h5" component="h2">
                            Your Ticket (#{busObPublicId}) was submitted.
                        </Typography>
                    </div>
                    <div className="pt10 pr10 pl10 pb5">
                        <Typography variant="h6" component="h2">
                            Please retain this ticket number for your records. 
                        </Typography>
                    </div>
                    <div className="pt10 pr10 pl10 pb5">
                        <Typography variant="h6" component="h2">
                            If this is urgent, we recommend contacting us over phone or chat. Thank you!
                        </Typography>
                    </div>
                    <Divider className="align-content-center pt10 pr10 pl10 pb5" />
                    <div className="pt20 pr10 pl10 pb5">
                        <Link to="#" className="textLink" onClick={() => setBusObPublicId(null)}>
                            Submit a new Ticket<KeyboardArrowRightIcon fontSize="small"/>
                        </Link>
                    </div>
                </Box>
            </Paper>
        </>
    );
    
}
