import React, {Component} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LogoDark from '../img/PPD_logo_purple-min.png';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Link} from 'react-router-dom';

export class Promo extends Component {
    
    static displayName = Promo.name;

    render() {
        return (
            <>
                <div className="aboutPPD">
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4} lg={4} />
                            <Grid item sm={12} md={8} lg={8}>
                                <img className="logo" src={LogoDark} alt="PPD - Part of Thermo Fisher Scientific"/>
                                <p>
                                    PPD is a leading global contract research organization providing comprehensive,
                                    integrated drug development, laboratory and lifecycle management services. Our
                                    clients and partners include pharmaceutical, biotechnology, medical device, academic
                                    and government organizations.
                                </p>
                                <div className="divider" />
                                <div>
                                    <a 
                                        href="http://www.ppd.com" 
                                        target="_blank" 
                                        className="textLink"
                                    >
                                        Visit us at PPD.com<KeyboardArrowRightIcon fontSize="small"/>
                                    </a>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </>
        );
    }
}
