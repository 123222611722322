/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PpdSupportPortalModelsClinicalSupportTicket model module.
 * @module model/PpdSupportPortalModelsClinicalSupportTicket
 * @version 1.0.0
 */
class PpdSupportPortalModelsClinicalSupportTicket {
    /**
     * Constructs a new <code>PpdSupportPortalModelsClinicalSupportTicket</code>.
     * @alias module:model/PpdSupportPortalModelsClinicalSupportTicket
     * @param system {String} 
     * @param firstName {String} 
     * @param lastName {String} 
     * @param emailAddress {String} 
     * @param country {String} 
     * @param callbackNumber {String} 
     * @param availability {String} 
     * @param requireTranslation {String} 
     * @param userId {String} 
     * @param protocol {String} 
     * @param siteNumber {String} 
     * @param role {String} 
     * @param comment {String} 
     */
    constructor(system, firstName, lastName, emailAddress, country, callbackNumber, availability, requireTranslation, userId, protocol, siteNumber, role, comment) { 
        
        PpdSupportPortalModelsClinicalSupportTicket.initialize(this, system, firstName, lastName, emailAddress, country, callbackNumber, availability, requireTranslation, userId, protocol, siteNumber, role, comment);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, system, firstName, lastName, emailAddress, country, callbackNumber, availability, requireTranslation, userId, protocol, siteNumber, role, comment) { 
        obj['system'] = system;
        obj['firstName'] = firstName;
        obj['lastName'] = lastName;
        obj['emailAddress'] = emailAddress;
        obj['country'] = country;
        obj['callbackNumber'] = callbackNumber;
        obj['availability'] = availability;
        obj['requireTranslation'] = requireTranslation;
        obj['userId'] = userId;
        obj['protocol'] = protocol;
        obj['siteNumber'] = siteNumber;
        obj['role'] = role;
        obj['comment'] = comment;
    }

    /**
     * Constructs a <code>PpdSupportPortalModelsClinicalSupportTicket</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PpdSupportPortalModelsClinicalSupportTicket} obj Optional instance to populate.
     * @return {module:model/PpdSupportPortalModelsClinicalSupportTicket} The populated <code>PpdSupportPortalModelsClinicalSupportTicket</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PpdSupportPortalModelsClinicalSupportTicket();

            if (data.hasOwnProperty('system')) {
                obj['system'] = ApiClient.convertToType(data['system'], 'String');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('emailAddress')) {
                obj['emailAddress'] = ApiClient.convertToType(data['emailAddress'], 'String');
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = ApiClient.convertToType(data['country'], 'String');
            }
            if (data.hasOwnProperty('callbackNumber')) {
                obj['callbackNumber'] = ApiClient.convertToType(data['callbackNumber'], 'String');
            }
            if (data.hasOwnProperty('availability')) {
                obj['availability'] = ApiClient.convertToType(data['availability'], 'String');
            }
            if (data.hasOwnProperty('requireTranslation')) {
                obj['requireTranslation'] = ApiClient.convertToType(data['requireTranslation'], 'String');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
            }
            if (data.hasOwnProperty('sponsor')) {
                obj['sponsor'] = ApiClient.convertToType(data['sponsor'], 'String');
            }
            if (data.hasOwnProperty('protocol')) {
                obj['protocol'] = ApiClient.convertToType(data['protocol'], 'String');
            }
            if (data.hasOwnProperty('siteNumber')) {
                obj['siteNumber'] = ApiClient.convertToType(data['siteNumber'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'String');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} system
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['system'] = undefined;

/**
 * @member {String} firstName
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['firstName'] = undefined;

/**
 * @member {String} lastName
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['lastName'] = undefined;

/**
 * @member {String} emailAddress
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['emailAddress'] = undefined;

/**
 * @member {String} country
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['country'] = undefined;

/**
 * @member {String} callbackNumber
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['callbackNumber'] = undefined;

/**
 * @member {String} availability
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['availability'] = undefined;

/**
 * @member {String} requireTranslation
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['requireTranslation'] = undefined;

/**
 * @member {String} userId
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['userId'] = undefined;

/**
 * @member {String} sponsor
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['sponsor'] = undefined;

/**
 * @member {String} protocol
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['protocol'] = undefined;

/**
 * @member {String} siteNumber
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['siteNumber'] = undefined;

/**
 * @member {String} role
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['role'] = undefined;

/**
 * @member {String} comment
 */
PpdSupportPortalModelsClinicalSupportTicket.prototype['comment'] = undefined;






export default PpdSupportPortalModelsClinicalSupportTicket;

