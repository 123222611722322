import type {InputHTMLAttributes} from "react";
import React from 'react';
import {Field} from "react-final-form";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    validate: () => {};
}

const FileField : typeof Field = ({ name, validate, refProp, ...props }: Props) => (
    <Field 
        name={name}
        type="file"
        validate={validate}
    >
        {({ input: { value, onChange, ...input } }) => (
            <input
                {...input}
                type="file"
                onChange={({ target }) => {
                    onChange(target.files);
                }} // instead of the default target.value
                {...props}
                accept=".png, .jpg, .jpeg, .gif"
                ref={refProp}
            />
        )}
    </Field>
);

export default FileField;