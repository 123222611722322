import React from 'react';
import Button from '@mui/material/Button';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import * as ppd_support_api from '../restapi';
import {PpdSupportPortalModelsAlert} from '../restapi';
import moment from 'moment';
import _ from 'lodash';
import Alerts_Module_AddEdit from "./Admin/Alerts/Alerts_Module_AddEdit";
import isGuid from "./helpers/validation";
import authService from "./api-authorization/AuthorizeService";

const columns = [
    {
        id: 'startDate',
        label: 'Start Date',
        minWidth: 50,
        format: (value) => {
            let m = moment(value);
            if (m.isValid()) {
                return m.format('DD-MMM-YYYY');
            } else {
                console.log('Admin > Alerts > Start Date invalid : ' + m.toString())
                return '';
            }
        }
    },
    {
        id: 'endDate',
        label: 'End Date',
        minWidth: 50,
        format: (value) => {
            let m = moment(value);
            if (m.isValid()) {
                return m.format('DD-MMM-YYYY');
            } else {
                console.log('Admin > Alerts > End Date invalid : ' + m.toString())
                return '';
            }
        }
    },
    {
        id: 'title',
        label: 'Title',
        minWidth: 500,
        format: (value) => {
            return value.length > 100 ? value.substring(0, 100) + "..." : value;
        },
        className: 'table-title',
    },
    {
        id: 'active',
        label: 'Status',
        minWidth: 100,
        align: 'left',
        format: (value) => {
            return value ? 'Active' : 'Inactive'
        },
    },
    {
        id: 'display',
        label: 'Display',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    }
];

export default function Alerts() {

    const [open, setOpen] = React.useState(false);
    const [alertsViewModel, setAlertsViewModel] = React.useState(null);
    const [rParams, setRParams] = React.useState({});
    const [alertToEdit, setAlertToEdit] = React.useState(null);

    let api = new ppd_support_api.AlertsApi();
    let params = {
        alertStatus: 10, //default to Active
    };

    /* <Functions> */
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = (alertData: PpdSupportPortalModelsAlert) => {
        const POST_PUT_Callback = (error, data) => {
            if (error) {
                console.error(error);
            } else {
                handleClose();
                api.getAdminAlertsViewModel(rParams, requestAlertVMCallback);
            }
        };
        const opts = { ppdSupportPortalModelsAlert: alertData };

        if (!alertData.key) {
            //ADD
            api.addAlert(opts, POST_PUT_Callback);
        } else {
            //UPDATE
            api.updateAlert(alertData.key, opts, POST_PUT_Callback);
        }
    };

    const handleExport = async () => {

        const token = await authService.getAccessToken();
        
        fetch("/api/admin/alerts-export", {
            "headers": {
                "authorization": `Bearer ${token}`,
                "cache-control": "no-cache",
                "pragma": "no-cache",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-origin"
            },
            "referrer": window.location.pathname,
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": null,
            "method": "GET",
            "mode": "cors",
            "credentials": "include"
        }).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                let generatedFileName = "alerts_" + moment().format("YYYY-MM-DD").toString() + ".csv"
                a.href = url;
                a.download = generatedFileName;
                a.click();
            });
            //window.location.href = response.url;
        }).catch((ex) => {
            console.log(ex);
        });
    };
    
    const handleDelete = () => {
        const DELETE_Callback = (error, data) => {
            if (error) {
                console.error(error);
            } else {
                handleClose();
                api.getAdminAlertsViewModel(params, requestAlertVMCallback);
            }
        };

        if (alertToEdit.key) {
            api.deleteAlert(alertToEdit.key, DELETE_Callback);
        }
    }

    const requestAlertVMCallback = (error, data) => {
        if (error) {
            console.error(error);
        } else {
            //console.log('API call to GET:/api/admin/alert/view-model successful.\nReturned data: ', data);
            setAlertsViewModel(data);
        }
    };

    const requestTargetAlertCallback = (error, data) => {
        if (error) {
            console.error(error);
        } else {
            //console.log('API call to GET:/api/admin/alert/{key:guid} successful.\nReturned data: ', data);
            setAlertToEdit(data);
        }
    };

    const handleModuleOpen = (key) => {
        if (isGuid(key)) {
            api.getAlert(key, requestTargetAlertCallback);
        } else {
            setAlertToEdit(null);
        }

        handleOpen();
    };

    const handleChangeStatus = (event) => {
        params = rParams;
        params.alertStatus = event.target.value;
        params.page = 1;
        setRParams(params);
        api.getAdminAlertsViewModel(params, requestAlertVMCallback);
    };

    const handleChangePage = (event, newPage) => {
        params = rParams;
        params.page = newPage + 1;
        setRParams(params);
        api.getAdminAlertsViewModel(params, requestAlertVMCallback);
    };

    const handleChangeRowsPerPage = (event) => {
        params = rParams;
        params.pageSize = (parseInt(event.target.value, 10));
        params.page = 1;
        setRParams(params);
        api.getAdminAlertsViewModel(params, requestAlertVMCallback);
    };

    const loadAlertsViewModelDebounced = _.debounce(() => {
        api.getAdminAlertsViewModel(params, requestAlertVMCallback);
    }, 500);

    const handleChangeSearch = (event) => {
        params = rParams;
        params.filter = event.target.value;
        params.page = 1;
        setRParams(params);
        loadAlertsViewModelDebounced();
    };
    /* </Functions> */

    /* <componentDidMount> */
    React.useEffect(() => {
        api.getAdminAlertsViewModel(params, requestAlertVMCallback);
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    /* <Data check> */
    if (!alertsViewModel) {
        return (
            <React.Fragment>
                <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="flex-column-mobile">
                        <Typography style={{marginTop: 8}}>
                            Loading Alerts
                        </Typography>
                    </Box>
                </Paper>
            </React.Fragment>
        );
    }
    /* </Data check> */

    return (
        <>
            <div className="page-header">
                <Alerts_Module_AddEdit
                    open={open}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    handleDelete={handleDelete}
                    alertToEdit={alertToEdit}
                />
                <h1>Alerts</h1>
                <div className="page-header-actions">
                    <Button
                        size="large"
                        variant="text"
                        className="mr10"
                        startIcon={<FileDownloadIcon/>}
                        onClick={handleExport}
                    >
                        Export Alerts History
                    </Button>

                    <Button
                        size="large"
                        variant="contained"
                        startIcon={<ControlPointIcon/>}
                        onClick={handleModuleOpen}
                    >
                        Add Alert
                    </Button>
                </div>
            </div>
            <Paper className="p20">
                <div className="table-header-actions">
                    <div className="search">
                        <SearchIcon className="icon"/>
                        <InputBase
                            placeholder="Search…"
                            inputProps={{'aria-label': 'search', maxLength: 50}}
                            onChange={handleChangeSearch}
                        />
                    </div>
                    <Box sx={{minWidth: 120}}>
                        <FormControl fullWidth>
                            <InputLabel id="">Status</InputLabel>
                            <Select
                                labelId="alertStatusFilter"
                                id="alertStatusFilter"
                                label="Status"
                                onChange={handleChangeStatus}
                                defaultValue={10}
                            >
                                <MenuItem value={'%'}>All</MenuItem>
                                <MenuItem value={10}>Active</MenuItem>
                                <MenuItem value={20}>Inactive</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <div className="admin-table">
                    <TableContainer sx={{maxHeight: '100%'}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth, fontWeight: "bold"}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {alertsViewModel && alertsViewModel.alerts.map((row: PpdSupportPortalModelsAlert) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.publicId}
                                            onClick={() => handleModuleOpen(row.key)}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} className={column.className}>
                                                        {column.format ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <TablePagination
                    //rowsPerPageOptions={[0]}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={alertsViewModel.countTotal}
                    rowsPerPage={alertsViewModel.pageSize}
                    page={alertsViewModel.page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

