/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PpdSupportPortalModelsAlert from './PpdSupportPortalModelsAlert';

/**
 * The PpdSupportPortalViewModelsAdminAlertsViewModel model module.
 * @module model/PpdSupportPortalViewModelsAdminAlertsViewModel
 * @version 1.0.0
 */
class PpdSupportPortalViewModelsAdminAlertsViewModel {
    /**
     * Constructs a new <code>PpdSupportPortalViewModelsAdminAlertsViewModel</code>.
     * @alias module:model/PpdSupportPortalViewModelsAdminAlertsViewModel
     */
    constructor() { 
        
        PpdSupportPortalViewModelsAdminAlertsViewModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PpdSupportPortalViewModelsAdminAlertsViewModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PpdSupportPortalViewModelsAdminAlertsViewModel} obj Optional instance to populate.
     * @return {module:model/PpdSupportPortalViewModelsAdminAlertsViewModel} The populated <code>PpdSupportPortalViewModelsAdminAlertsViewModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PpdSupportPortalViewModelsAdminAlertsViewModel();

            if (data.hasOwnProperty('alerts')) {
                obj['alerts'] = ApiClient.convertToType(data['alerts'], [PpdSupportPortalModelsAlert]);
            }
            if (data.hasOwnProperty('page')) {
                obj['page'] = ApiClient.convertToType(data['page'], 'Number');
            }
            if (data.hasOwnProperty('pageSize')) {
                obj['pageSize'] = ApiClient.convertToType(data['pageSize'], 'Number');
            }
            if (data.hasOwnProperty('countTotal')) {
                obj['countTotal'] = ApiClient.convertToType(data['countTotal'], 'Number');
            }
            if (data.hasOwnProperty('textFieldFilter')) {
                obj['textFieldFilter'] = ApiClient.convertToType(data['textFieldFilter'], 'String');
            }
            if (data.hasOwnProperty('dropdownFilter')) {
                obj['dropdownFilter'] = ApiClient.convertToType(data['dropdownFilter'], 'String');
            }
            if (data.hasOwnProperty('columnToOrderBy')) {
                obj['columnToOrderBy'] = ApiClient.convertToType(data['columnToOrderBy'], 'String');
            }
            if (data.hasOwnProperty('columnDirectionalityToOrderBy')) {
                obj['columnDirectionalityToOrderBy'] = ApiClient.convertToType(data['columnDirectionalityToOrderBy'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/PpdSupportPortalModelsAlert>} alerts
 */
PpdSupportPortalViewModelsAdminAlertsViewModel.prototype['alerts'] = undefined;

/**
 * @member {Number} page
 */
PpdSupportPortalViewModelsAdminAlertsViewModel.prototype['page'] = undefined;

/**
 * @member {Number} pageSize
 */
PpdSupportPortalViewModelsAdminAlertsViewModel.prototype['pageSize'] = undefined;

/**
 * @member {Number} countTotal
 */
PpdSupportPortalViewModelsAdminAlertsViewModel.prototype['countTotal'] = undefined;

/**
 * @member {String} textFieldFilter
 */
PpdSupportPortalViewModelsAdminAlertsViewModel.prototype['textFieldFilter'] = undefined;

/**
 * @member {String} dropdownFilter
 */
PpdSupportPortalViewModelsAdminAlertsViewModel.prototype['dropdownFilter'] = undefined;

/**
 * @member {String} columnToOrderBy
 */
PpdSupportPortalViewModelsAdminAlertsViewModel.prototype['columnToOrderBy'] = undefined;

/**
 * @member {String} columnDirectionalityToOrderBy
 */
PpdSupportPortalViewModelsAdminAlertsViewModel.prototype['columnDirectionalityToOrderBy'] = undefined;






export default PpdSupportPortalViewModelsAdminAlertsViewModel;

