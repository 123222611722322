import React from 'react';
import {Field} from 'react-final-form';
import moment from 'moment';
import {isValidDate} from "../helpers/validation";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";

export const Datepicker: typeof Field = (
    { 
        name, 
        helperText, 
        validate, 
        format, 
        onChange, 
        displayLabel, 
        inputLabel, 
        tooltip, 
        placeholder, 
        required, 
        ...rest 
    }) =>
        <Field name={name} validate={validate} render={({ input, meta }) => {
            const date: any = input.value;

            let shiftedDate: any = new Date();
            try {
                shiftedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            }
            catch
            {
                //ignore this
            }

            return (
                <React.Fragment>
                    <DatePicker
                        label={displayLabel}
                        inputFormat={moment().localeData().longDateFormat('L')}
                        toolbarPlaceholder={moment().localeData().longDateFormat('L')}
                        onChange={(val: moment.Moment) => {
                            let d: Date = null;
                            if (isValidDate(val?.toDate())) {
                                d = val.toDate();
                                d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
                            }
                            input.onChange(d);
                            if (onChange) {
                                onChange(d);
                            }
                        }}
                        value={shiftedDate}
                        renderInput={(params) => <TextField {...params} />}
                        {...rest}
                    />
                </React.Fragment>
            );
        }} />
