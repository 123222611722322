import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as ppd_support_api from "../restapi";
import PpdSupportPortalModelsSettings from "../restapi/model/PpdSupportPortalModelsSettings";
import TimePicker from '@mui/lab/TimePicker';
import {TextField} from "@mui/material";
import moment from "moment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import authService from "./api-authorization/AuthorizeService";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import {Link} from "react-router-dom";

export default function Settings() {

    const [setting, setSetting] = React.useState(null);
    const [settingsJson, setSettingsJson] = React.useState('{}');
    
    const [csHomeDisclaimer, setCsHomeDisclaimer] = React.useState(null);
    const [psHomeDisclaimer, setPsHomeDisclaimer] = React.useState(null); 
    
    const [startDay, setStartDay] = React.useState(0);
    const [startTime, setStartTime] = React.useState(moment.now);
    const [endDay, setEndDay] = React.useState(5);
    const [endTime, setEndTime] = React.useState(moment.now);
    
    const [chatHoursDisclaimer, setChatHoursDisclaimer] = React.useState(null);
    const [csChatFormDisclaimer, setCsChatFormDisclaimer] = React.useState(null);
    const [psChatFormDisclaimer, setPsChatFormDisclaimer] = React.useState(null);
    const [csTicketFormDisclaimer, setCsTicketFormDisclaimer] = React.useState(null);
    const [psTicketFormDisclaimer, setPsTicketFormDisclaimer] = React.useState(null);    
        
    let settingsApi = new ppd_support_api.SettingsApi();

    /* <Functions> */
    const requestSettingsCallback = (error, data: PpdSupportPortalModelsSettings) => {
        if (error) {
            console.error(error);
        } else {
            //console.log('API call to GET:/api/admin/settings successful.\nReturned data: ', data);
            setSetting(data);

            setSettingsJson(data?.appSettings || '{}');

            if (data?.appSettings) {
                let s = JSON.parse(data.appSettings);
                
                setStartDay(s.startDay ?? 0);
                setStartTime(moment(s.startTime, 'HH:mm'));
                setEndDay(s.endDay ?? 5);
                setEndTime(moment(s.endTime, 'HH:mm'));
                
                setChatHoursDisclaimer(s.chatHoursDisclaimer);
                setCsHomeDisclaimer(s.csHomeDisclaimer);
                setPsHomeDisclaimer(s.psHomeDisclaimer);
                setCsChatFormDisclaimer(s.csChatFormDisclaimer);
                setPsChatFormDisclaimer(s.psChatFormDisclaimer);
                setCsTicketFormDisclaimer(s.csTicketFormDisclaimer);
                setPsTicketFormDisclaimer(s.psTicketFormDisclaimer);
            } else {
                let newTime = new Date;
                
                setStartDay(0);
                setStartTime(moment(newTime, 'HH:mm'));
                
                setEndDay(5);
                setEndTime(moment(newTime, 'HH:mm'));
                
                setChatHoursDisclaimer(null);

                let s = JSON.parse(settingsJson);
                s.startTime = moment(newTime).format('HH:mm');
                s.endTime = moment(newTime).format('HH:mm');
                setSettingsJson(JSON.stringify(s));
            }
        }
    };
    
    const handleSubmit = () => {
        let data = setting || {};
        data.appSettings = settingsJson;
        
        let opts = {ppdSupportPortalModelsSettings: data};
        settingsApi.addSettingsVersion(opts, requestSettingsCallback);
        
        alert('Settings Updated');
    };

    const handleExport = async () => {

        const token = await authService.getAccessToken();

        fetch("/api/admin/settings-export", {
            "headers": {
                "authorization": `Bearer ${token}`,
                "cache-control": "no-cache",
                "pragma": "no-cache",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-origin"
            },
            "referrer": window.location.pathname,
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": null,
            "method": "GET",
            "mode": "cors",
            "credentials": "include"
        }).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                let generatedFileName = "settings_" + moment().format("YYYY-MM-DD").toString() + ".csv"
                a.href = url;
                a.download = generatedFileName;
                a.click();
            });
            //window.location.href = response.url;
        }).catch((ex) => {
            console.log(ex);
        });
    };
    
    const handleStartDaySelect = (e) => {
        setStartDay(e.target.value);
        let s = JSON.parse(settingsJson);
        s.startDay = e.target.value.toString();
        setSettingsJson(JSON.stringify(s));
    };

    const handleEndDaySelect = (e) => {
        setEndDay(e.target.value);
        let s = JSON.parse(settingsJson);
        s.endDay = e.target.value.toString();
        setSettingsJson(JSON.stringify(s));
    };
    /* </Functions> */

    /* <componentDidMount> */
    React.useEffect(() => {
        settingsApi.getAdminSettings(requestSettingsCallback);
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    /* <Data check> */
    if (!settingsJson) {
        return (
            <React.Fragment>
                <Paper className="expansion-panels pr20 pl20 pt5 pb20">
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center"
                         className="flex-column-mobile">
                        <Typography style={{marginTop: 8}}>
                            Loading Application Settings
                        </Typography>
                    </Box>
                </Paper>
            </React.Fragment>
        );
    }
    /* </Data check> */
    
    return (
        <>
            <div className="page-header">
                <h1>Settings</h1>
                <div className="page-header-actions">
                    <Button
                        size="large"
                        variant="text"
                        className="mr10"
                        startIcon={<FileDownloadIcon/>}
                        onClick={handleExport}
                    >
                        Export Settings History
                    </Button>
                </div>
            </div>
            <Paper className="p20">
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <Grid container spacing={3} className="pt20">
                        <Grid item md={12}><h3>Hours of Operation</h3></Grid>
                        <Grid item xs={12} sm={12} md={2}>
                            <FormControl fullWidth>
                                <InputLabel id="startDay">Start Day (Eastern Time)</InputLabel>
                                <Select
                                    labelId="startDay"
                                    id="startDay"
                                    label="Start Day (Eastern Time)"
                                    onChange={handleStartDaySelect}
                                    value={startDay}
                                >
                                    <MenuItem value={0}>Sun</MenuItem>
                                    <MenuItem value={1}>Mon</MenuItem>
                                    <MenuItem value={2}>Tue</MenuItem>
                                    <MenuItem value={3}>Wed</MenuItem>
                                    <MenuItem value={4}>Thu</MenuItem>
                                    <MenuItem value={5}>Fri</MenuItem>
                                    <MenuItem value={6}>Sat</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TimePicker
                                label="Start Time (Eastern Time)"
                                value={startTime}
                                onChange={(newTime) => {
                                    setStartTime(newTime);
                                    let s = JSON.parse(settingsJson);
                                    s.startTime = moment(newTime).format('HH:mm');
                                    setSettingsJson(JSON.stringify(s));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat={moment().localeData().longDateFormat('LT')}
                                toolbarPlaceholder={moment().localeData().longDateFormat('LT')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} />
                        <Grid item xs={12} sm={12} md={2}>
                            <FormControl fullWidth>
                                <InputLabel id="endDay">End Day (Eastern Time)</InputLabel>
                                <Select
                                    labelId="endDay"
                                    id="endDay"
                                    label="End Day (Eastern Time)"
                                    onChange={handleEndDaySelect}
                                    value={endDay}
                                >
                                    <MenuItem value={0}>Sun</MenuItem>
                                    <MenuItem value={1}>Mon</MenuItem>
                                    <MenuItem value={2}>Tue</MenuItem>
                                    <MenuItem value={3}>Wed</MenuItem>
                                    <MenuItem value={4}>Thu</MenuItem>
                                    <MenuItem value={5}>Fri</MenuItem>
                                    <MenuItem value={6}>Sat</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TimePicker
                                label="End Time (Eastern Time)"
                                value={endTime}
                                onChange={(newTime) => {
                                    setEndTime(newTime);
                                    let s = JSON.parse(settingsJson);
                                    s.endTime = moment(newTime).format('HH:mm');
                                    setSettingsJson(JSON.stringify(s));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat={moment().localeData().longDateFormat('LT')}
                                toolbarPlaceholder={moment().localeData().longDateFormat('LT')}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                id="chatHoursDisclaimer"
                                label="After Hours Chat Disclaimer"
                                variant="outlined"
                                rows={3}
                                multiline
                                value={chatHoursDisclaimer || ''}
                                onChange={(e) => {
                                    setChatHoursDisclaimer(e.target.value);
                                    let s = JSON.parse(settingsJson);
                                    s.chatHoursDisclaimer = e.target.value;
                                    setSettingsJson(JSON.stringify(s));
                                }}
                                error={chatHoursDisclaimer == null || chatHoursDisclaimer?.length < 1}
                                helperText={chatHoursDisclaimer == null || chatHoursDisclaimer?.length < 1 ? 'Empty field!' : ' '}
                            />
                        </Grid>

                        <Grid item md={12}><h3>Clinical Support Site</h3></Grid>
                        <Grid item md={12}>
                            <TextField
                                id="csHomeDisclaimer"
                                label="Clinical Systems Support Home Disclaimer"
                                variant="outlined"
                                rows={3}
                                multiline
                                value={csHomeDisclaimer || ''}
                                onChange={(e) => {
                                    setCsHomeDisclaimer(e.target.value);
                                    let s = JSON.parse(settingsJson);
                                    s.csHomeDisclaimer = e.target.value;
                                    setSettingsJson(JSON.stringify(s));
                                }}
                                //error={csHomeDisclaimer == null || csHomeDisclaimer?.length < 1}
                                //helperText={csHomeDisclaimer == null || csHomeDisclaimer?.length < 1 ? 'Empty field!' : ' '}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                id="csChatFormDisclaimer"
                                label="Clinical Systems Support Chat Form Disclaimer"
                                variant="outlined"
                                rows={3}
                                multiline
                                value={csChatFormDisclaimer || ''}
                                onChange={(e) => {
                                    setCsChatFormDisclaimer(e.target.value);
                                    let s = JSON.parse(settingsJson);
                                    s.csChatFormDisclaimer = e.target.value;
                                    setSettingsJson(JSON.stringify(s));
                                }}
                                //error={csChatFormDisclaimer == null || csChatFormDisclaimer?.length < 1}
                                //helperText={csChatFormDisclaimer == null || csChatFormDisclaimer?.length < 1 ? 'Empty field!' : ' '}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                id="csTicketFormDisclaimer"
                                label="Clinical Systems Support Ticket Form Disclaimer"
                                variant="outlined"
                                rows={3}
                                multiline
                                value={csTicketFormDisclaimer || ''}
                                onChange={(e) => {
                                    setCsTicketFormDisclaimer(e.target.value);
                                    let s = JSON.parse(settingsJson);
                                    s.csTicketFormDisclaimer = e.target.value;
                                    setSettingsJson(JSON.stringify(s));
                                }}
                                //error={csTicketFormDisclaimer == null || csTicketFormDisclaimer?.length < 1}
                                //helperText={csTicketFormDisclaimer == null || csTicketFormDisclaimer?.length < 1 ? 'Empty field!' : ' '}
                            />
                        </Grid>

                        <Grid item md={12}><h3>Patient Support Site</h3></Grid>
                        <Grid item md={12}>
                            <TextField
                                id="psHomeDisclaimer"
                                label="Patient Support Home Disclaimer"
                                variant="outlined"
                                rows={3}
                                multiline
                                value={psHomeDisclaimer || ''}
                                onChange={(e) => {
                                    setPsHomeDisclaimer(e.target.value);
                                    let s = JSON.parse(settingsJson);
                                    s.psHomeDisclaimer = e.target.value;
                                    setSettingsJson(JSON.stringify(s));
                                }}
                                //error={psHomeDisclaimer == null || psHomeDisclaimer?.length < 1}
                                //helperText={psHomeDisclaimer == null || psHomeDisclaimer?.length < 1 ? 'Empty field!' : ' '}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                id="psChatFormDisclaimer"
                                label="Patient Support Chat Form Disclaimer"
                                variant="outlined"
                                rows={3}
                                multiline
                                value={psChatFormDisclaimer || ''}
                                onChange={(e) => {
                                    setPsChatFormDisclaimer(e.target.value);
                                    let s = JSON.parse(settingsJson);
                                    s.psChatFormDisclaimer = e.target.value;
                                    setSettingsJson(JSON.stringify(s));
                                }}
                                //error={psChatFormDisclaimer == null || psChatFormDisclaimer?.length < 1}
                                //helperText={psChatFormDisclaimer == null || psChatFormDisclaimer?.length < 1 ? 'Empty field!' : ' '}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                id="psTicketFormDisclaimer"
                                label="Patient Support Ticket Form Disclaimer"
                                variant="outlined"
                                rows={3}
                                multiline
                                value={psTicketFormDisclaimer || ''}
                                onChange={(e) => {
                                    setPsTicketFormDisclaimer(e.target.value);
                                    let s = JSON.parse(settingsJson);
                                    s.psTicketFormDisclaimer = e.target.value;
                                    setSettingsJson(JSON.stringify(s));
                                }}
                                //error={psTicketFormDisclaimer == null || psTicketFormDisclaimer?.length < 1}
                                //helperText={psTicketFormDisclaimer == null || psTicketFormDisclaimer?.length < 1 ? 'Empty field!' : ' '}
                            />
                        </Grid>

                        {/*<Grid item md={12}>JSON Preview</Grid>*/}
                        {/*<Grid item xs={12} sm={12}>*/}
                        {/*    <TextField*/}
                        {/*        className="textfield"*/}
                        {/*        label="Application Settings"*/}
                        {/*        variant="outlined"*/}
                        {/*        rows={3}*/}
                        {/*        multiline*/}
                        {/*        value={settingsJson}*/}
                        {/*        disabled={true}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sm={12}>
                            <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end">
                                <Link to="/admin" style={{ textDecoration: 'none' }}>
                                    <Button size="large" variant="outlined">Cancel</Button>
                                </Link>
                                <Button 
                                    size="large" 
                                    variant="contained" 
                                    onClick={handleSubmit} 
                                    disabled={setting ? (setting?.appSettings === settingsJson) : (chatHoursDisclaimer == null || chatHoursDisclaimer?.length < 1)}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    );
}

