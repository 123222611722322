import React from "react";
import {PpdSupportPortalModelsAlert} from "../../../restapi";
import moment from "moment";
import Home_AlertsList_LearnMore from "./Home_AlertsList_LearnMore";
import isGuid from "../../helpers/validation";

export default function Home_AlertsList(props) {

    const [open, setOpen] = React.useState(false);
    const [alertsList, setAlertsList] = React.useState(null);
    const [alertToView, setAlertToView] = React.useState(null);

    /* <Functions> */
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleModuleOpen = (key) => {
        if (isGuid(key)) {
            let targetAlert = alertsList.find((alert) => { return alert.key === key });
            setAlertToView(targetAlert ?? null);
        } else {
            setAlertToView(null);
        }

        handleOpen();
    };
    /* </Functions> */

    /* <componentDidMount> */
    React.useEffect(() => {
        setAlertsList(props.siteData && props.siteData.alerts);
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    /* <Data check> */
    if (!alertsList) {
        return (
            <React.Fragment>
                {/*<Paper className="expansion-panels pr20 pl20 pt5 pb20">*/}
                {/*    <Box */}
                {/*        display="flex" */}
                {/*        flexDirection="row" */}
                {/*        alignItems="center" */}
                {/*        justifyContent="center" */}
                {/*        className="flex-column-mobile"*/}
                {/*    >*/}
                {/*        <Typography style={{marginTop: 8}}>*/}
                {/*            Loading Alerts*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*</Paper>*/}
            </React.Fragment>
        );
    }
    /* </Data check> */
    
    return (
        <>
            <Home_AlertsList_LearnMore
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                alertToView={alertToView}
            />
            {alertsList && alertsList.map((row: PpdSupportPortalModelsAlert) => {
                return (
                    <React.Fragment>
                        <div className="alert" key={row.key} onClick={() => handleModuleOpen(row.key)}>
                            <div className="info">
                                <div>{row.title.length > 60 ? row.title.substring(0, 60) + '...' : row.title}</div>
                            </div>
                            <div className="link">
                                <a onClick={() => {return false;}}>Learn More</a>
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
        </>
    );
}
