import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LogoLight from '../img/PPD_logo_white-min.png';
import Home_AlertsList from "./Frontend/HomeComponents/Home_AlertsList";
import HomeTabs from "./Frontend/HomeTabsComponents/HomeTabs";
import Globals from "../Globals";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function Home(props) {

    const [siteData] = React.useState(props.siteData);

    /* <componentDidMount> */
    React.useEffect(() => {
        Globals.instance = new Globals();
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    /* </componentDidMount> */

    return (
        <>
            <div className={`support-hero ${Globals.instance.isClinical() ? 'clinical' : ''} pt20 pr20 pl20 pb40`} aria-label="PPD Home Section">
                <Container maxWidth="lg" className="pt10 pb50">
                    <img className="logo" src={LogoLight} alt="PPD - Part of Thermo Fisher Scientific" />
                    <Grid container spacing={3}>
                        <Grid item sm={12} md={8} lg={7}>
                            <h1>
                                <span className="green" aria-label="Forked Home Page">{Globals.instance.isPatient() && 'Patient'}{Globals.instance.isClinical() && 'Clinical Systems'}</span> Support
                            </h1>
                            <p>
                                Welcome to PPD’s {Globals.instance.isPatient() && 'Patient'}{Globals.instance.isClinical() && 'Clinical Systems'} Support. Connect with a member of our support team through one of the channels below.
                            </p>
                            <hr/>
                       
                            <p>
                                {Globals.instance.isPatient() && <em className="text-yellow">{siteData ? siteData.settings?.appSettings?.psHomeDisclaimer : 'If you are contacting us for a medical emergency, please contact your physician or the public emergency hotline.'}</em>}
                                {Globals.instance.isClinical() && <em>{siteData ? siteData.settings?.appSettings?.csHomeDisclaimer : 'If you are contacting us for a medical emergency, please contact your physician or the public emergency hotline.'}</em>}
                            </p>
                            {Globals.instance.isClinical() && 
                                <p>
                                    If you are a patient, visit our <a href='/patient' className="textLink-yellow">Patient Support Portal<KeyboardArrowRightIcon fontSize="small"/></a>
                                </p>
                            }

                            <Home_AlertsList
                                siteData={siteData}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <HomeTabs />
        </>
    );
}
