import * as React from 'react';
import Menu from '@mui/material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {LoginMenu} from "./api-authorization/LoginMenu";

export default function AccountMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className="account-menu">
            <Tooltip title="Account settings">
                <IconButton onClick={handleClick} size="small" sx={{ml: 1, mr: 1}}>
                    <AccountCircleOutlinedIcon sx={{width: 32, height: 32}}/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <div className="menu-popover">
                    <div style={{minWidth:200}}><LoginMenu /></div>
                    {/*<h3>Jan Doe</h3>*/}
                    {/*<p>jandoe@ppdi.com</p>*/}
                    {/*<Button variant="contained" size="small" startIcon={<LogoutIcon/>}>Sign Out</Button>*/}
                </div>
            </Menu>
        </div>
    );
}