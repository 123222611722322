import {FormHelperText, InputLabel, Select} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import {Field} from 'react-final-form';

export const SelectInput: typeof Field = (
    {
        name, 
        helperText,
        formControlClassName, 
        validate, 
        options, 
        displayLabel, 
        variant, 
        inputLabel, 
        fullWidth, 
        shrink, 
        onChange, 
        tooltip, 
        required, 
        ...rest 
    }) =>
        <Field
            name={name}
            validate={validate}
            render={({ input, meta }) =>
                <FormControl error={meta.touched && meta.error} variant="outlined" className={formControlClassName || "select"} fullWidth={fullWidth} >
                    <InputLabel shrink htmlFor="select-multiple-native">
                        {displayLabel}
                    </InputLabel>
                    <Select
                        onChange={onChange}
                        inputProps={input}
                        {...rest}
                    >
                        {options}
                    </Select>
                    {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
                    {!(meta.touched && meta.error) && helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            }
        />