import React from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import LaunchIcon from '@mui/icons-material/Launch';
import authService from "./api-authorization/AuthorizeService";
import moment from "moment";

var timeoutCheck = null;

export default function AdminNav() {

    /**
     * PPDSP-12 DS.1 - The system shall automatically log the user out of the system and/or lock the workstation if the no activity has occurred within the session for more than fifteen (15) minutes.
     * runs a check to execute endpoint to ensure users is logged out after 15min
     * @returns {Promise<void>}
     */
    const setupTimeoutCheck = async () => {
        const token = await authService.getAccessToken();
        clearInterval(timeoutCheck);
        timeoutCheck = setInterval(()=>{
            fetch("/connect/userinfo",{
                "headers": {
                    "authorization": `Bearer ${token}`,
                    "cache-control": "no-cache",
                    "pragma": "no-cache",
                    "sec-fetch-dest": "empty",
                    "sec-fetch-mode": "cors",
                    "sec-fetch-site": "same-origin"
                }
            });
        },16 * 60 * 1000);//16min
    }

    const handleExport = async () => {

        const token = await authService.getAccessToken();
        

        fetch("/api/admin/events-export", {
            "headers": {
                "authorization": `Bearer ${token}`,
                "cache-control": "no-cache",
                "pragma": "no-cache",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-origin"
            },
            "referrer": window.location.pathname,
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": null,
            "method": "GET",
            "mode": "cors",
            "credentials": "include",
        }).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                let generatedFileName = "ActivityLog_" + moment().format("YYYY-MM-DD").toString() + ".csv"
                a.href = url;
                a.download = generatedFileName;
                a.click();
            });
            //window.location.href = response.url;
        });
    };

    setupTimeoutCheck();

    
    
    return (
        <div className="nav-menu admin-menu pb20">
            <div className="p20">
                <Link
                    to='/admin'
                    //onClick={() => this.handleListItemClick(null)}
                >
                    <Typography variant="h5" className="main-menu">
                        Main Menu
                    </Typography>
                </Link>
            </div>
            <Divider/>
            <List className="nav-list">
                <ListItem
                    button
                    component={Link}
                    to="/admin/alerts"
                    key="Alerts"
                    // onClick={() => this.handleListItemClick('alerts')}
                    // className={(this.state.selectedIndex === 'alerts' || window.location.href.endsWith('alerts')) ? "active" : ""}
                    className={window.location.href.endsWith('alerts') ? "active" : ""}
                >
                    <ListItemText primary="Alerts"/>
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/admin/countries"
                    key="Countries"
                    //onClick={() => this.handleListItemClick('countries')}
                    //className={(this.state.selectedIndex === 'countries' || window.location.href.endsWith('countries')) ? "active" : ""}
                    className={window.location.href.endsWith('countries') ? "active" : ""}
                >
                    <ListItemText primary="Countries"/>
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/admin/settings"
                    key="Settings"
                    //onClick={() => this.handleListItemClick('settings')}
                    //className={(this.state.selectedIndex === 'settings' || window.location.href.endsWith('settings')) ? "active" : ""}
                    className={window.location.href.endsWith('settings') ? "active" : ""}
                >
                    <ListItemText primary="Settings"/>
                </ListItem>
                <ListItem
                    button
                    onClick={handleExport}
                    key="Activity Log"
                >
                    <DownloadIcon className="pr10"/>
                    <ListItemText primary="Activity Log"/>
                </ListItem>
                <Divider/>
                <ListItem
                    component={"a"}
                    href={"/"}
                    target={"_blank"}
                    key={"View Clinical Systems Support Site"}
                >
                    <LaunchIcon className="pr10"/>
                    <ListItemText primary="View Clinical Systems Support Site"/>
                </ListItem>
                <ListItem
                    component={"a"}
                    href={"/patient"}
                    target={"_blank"}
                    key={"View Patient Support Site"}
                >
                    <LaunchIcon className="pr10"/>
                    <ListItemText primary="View Patient Support Site"/>
                </ListItem>
            </List>
        </div>
    );
}
