import { isString } from 'lodash';

export const Required = message => value => {
    console.log("Required", !primitiveEmpty(value) || message)
    return !primitiveEmpty(value) ? undefined : message;
};

export const StringRequired = (min, max, message) => value => {
    const valueToCheck = value && value.value || value;

    console.log("String Required: ", isString(valueToCheck) && valueToCheck.length >= min && valueToCheck.length <= max || message);
    return isString(valueToCheck) && valueToCheck.length >= min && valueToCheck.length <= max ? undefined : message;
};

export const StringOptional = (min, max, message) => value => {
    const valueToCheck = value && value.value || value;
    return primitiveEmpty(valueToCheck) ? undefined : StringRequired(min, max, message)(value);
};

export const NumberRequired = (min, max, message) => value => {
    return !isNaN(value) && value >= min && value <= max ? undefined : message;
};

export const NumberOptional = (min, max, message) => value => {
    return primitiveEmpty(value) || value === 0 ? undefined : NumberRequired(min, max, message);
};

export const RegexRequired = (match, options, message) => value => {
    const regex = new RegExp(match, options);
    return regex.test(value) ? undefined : message;
};

export const InverseRegexRequired = (match, options, message) => value => {
    const regex = new RegExp(match, options);
    return regex.test(value) ? message : undefined;
};

export const RegexOptional = (match, options, message) => value => {
    return (
        primitiveEmpty(value) ? undefined : RegexRequired(match, options, message)(value)
    );
};

export const composeValidators = (...validators) => value => {
    const valid = validators.reduce(
        (errors, validator) => {
            const newError = validator(value);
            if (newError)
                errors.push(newError);

            return errors;
        }, []
    );

    return valid.length === 0 ? undefined : valid.join("\n");
}

export const primitiveEmpty = value => {
    return !value && value !== 0;
};

// General Email Regex (RFC 5322 Offical)
export const EmailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default function isGuid(value) {
    const regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
    let match = regex.exec(value);
    return match != null;
}

export const isValidDate = (d: any): boolean => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        return !isNaN(d.getTime());
    } else {
        // not a date
        return false;
    }
}