import React from 'react';
import Container from '@mui/material/Container';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from '@mui/material/Button';
import Globals from "../Globals";
import Home from "./Home";
import {Promo} from "./Promo";
import Footer from "./Footer";
import Chat_PatientForm from "./Frontend/ChatComponents/Chat_PatientForm";
import Chat_ClinicalForm from "./Frontend/ChatComponents/Chat_ClinicalForm";
import moment from "moment-timezone";
import Chat_OutsideOpenHours from "./Frontend/ChatComponents/Chat_OutsideOpenHours";
import {Link} from "react-router-dom";

const withinOpenHoursChecker = (settingsJson): boolean => {
    let startDayOfTheWeek = moment(settingsJson?.startTime, 'dddd LT').day(),
        endDayOfTheWeek =  moment(settingsJson?.endTime, 'dddd LT').day();
    
    let startDate = moment(settingsJson?.startTime, 'dddd LT').toDate();
    
    let endDate;    
    if (endDayOfTheWeek < startDayOfTheWeek) { //We need to compare against "next week's end date"
        endDate = moment(settingsJson?.endTime, 'dddd LT z').add(1, 'weeks').toDate();
    } else {
        endDate = moment(settingsJson?.endTime, 'dddd LT z').toDate();
    }
    
    return startDate < moment() && moment() < endDate;
}

export default function Chat(props) {

    const [settingsJson] = React.useState(props.siteData && props.siteData.settings && props.siteData.settings.appSettings);
    const [webchatBaseUrl] = React.useState(props.siteData && props.siteData.webchatBaseUrl);
    const [withinOpenHours] = React.useState(withinOpenHoursChecker(settingsJson));
    
    return (
        <>
            <Home siteData={props.siteData} />
            <Container maxWidth="lg" className="mb50">
                <div className="section-header">
                    <div className="section-back mb20">
                        <Link to={'/'} className='textLink'>
                            <Button size="large" startIcon={<KeyboardArrowLeftIcon/>}><b>Back</b></Button>
                        </Link>
                    </div>
                    <div className="label">
                        <span className="green">Chat</span> with a Support Specialist
                    </div>
                    <div className="hours">{settingsJson?.startTime ?? 'N/A'} – {settingsJson?.endTime ?? 'N/A'}</div>
                </div>
                <p className="mb50">
                    <em>
                        {Globals.instance.isPatient() && (settingsJson?.psChatFormDisclaimer ?? '')}
                        {Globals.instance.isClinical() && (settingsJson?.csChatFormDisclaimer ?? '')}
                    </em>
                </p>

                {withinOpenHours && 
                    (Globals.instance.isClinical() 
                        ? <Chat_ClinicalForm webchatUrl={webchatBaseUrl} /> 
                        : <Chat_PatientForm webchatUrl={webchatBaseUrl} />
                    )
                }
                
                {!withinOpenHours && <Chat_OutsideOpenHours siteData={props.siteData} />}

            </Container>
            <Promo />
            <Footer />
        </>
    );
}
