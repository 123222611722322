/**
 * PPD Support Portal
 * PPD Support Portal (ASP.NET Core 3.0)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PpdSupportPortalModelsPatientSupportTicket model module.
 * @module model/PpdSupportPortalModelsPatientSupportTicket
 * @version 1.0.0
 */
class PpdSupportPortalModelsPatientSupportTicket {
    /**
     * Constructs a new <code>PpdSupportPortalModelsPatientSupportTicket</code>.
     * @alias module:model/PpdSupportPortalModelsPatientSupportTicket
     * @param contactPreference {String} 
     * @param emailAddress {String} 
     * @param availability {String} 
     * @param requireTranslation {String} 
     * @param comment {String} 
     */
    constructor(contactPreference, emailAddress, availability, requireTranslation, comment) { 
        
        PpdSupportPortalModelsPatientSupportTicket.initialize(this, contactPreference, emailAddress, availability, requireTranslation, comment);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, contactPreference, emailAddress, availability, requireTranslation, comment) { 
        obj['contactPreference'] = contactPreference;
        obj['emailAddress'] = emailAddress;
        obj['availability'] = availability;
        obj['requireTranslation'] = requireTranslation;
        obj['comment'] = comment;
    }

    /**
     * Constructs a <code>PpdSupportPortalModelsPatientSupportTicket</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PpdSupportPortalModelsPatientSupportTicket} obj Optional instance to populate.
     * @return {module:model/PpdSupportPortalModelsPatientSupportTicket} The populated <code>PpdSupportPortalModelsPatientSupportTicket</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PpdSupportPortalModelsPatientSupportTicket();

            if (data.hasOwnProperty('subjectNumber')) {
                obj['subjectNumber'] = ApiClient.convertToType(data['subjectNumber'], 'String');
            }
            if (data.hasOwnProperty('contactPreference')) {
                obj['contactPreference'] = ApiClient.convertToType(data['contactPreference'], 'String');
            }
            if (data.hasOwnProperty('callbackNumber')) {
                obj['callbackNumber'] = ApiClient.convertToType(data['callbackNumber'], 'String');
            }
            if (data.hasOwnProperty('emailAddress')) {
                obj['emailAddress'] = ApiClient.convertToType(data['emailAddress'], 'String');
            }
            if (data.hasOwnProperty('availability')) {
                obj['availability'] = ApiClient.convertToType(data['availability'], 'String');
            }
            if (data.hasOwnProperty('requireTranslation')) {
                obj['requireTranslation'] = ApiClient.convertToType(data['requireTranslation'], 'String');
            }
            if (data.hasOwnProperty('applicationName')) {
                obj['applicationName'] = ApiClient.convertToType(data['applicationName'], 'String');
            }
            if (data.hasOwnProperty('deviceOs')) {
                obj['deviceOs'] = ApiClient.convertToType(data['deviceOs'], 'String');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} subjectNumber
 */
PpdSupportPortalModelsPatientSupportTicket.prototype['subjectNumber'] = undefined;

/**
 * @member {String} contactPreference
 */
PpdSupportPortalModelsPatientSupportTicket.prototype['contactPreference'] = undefined;

/**
 * @member {String} callbackNumber
 */
PpdSupportPortalModelsPatientSupportTicket.prototype['callbackNumber'] = undefined;

/**
 * @member {String} emailAddress
 */
PpdSupportPortalModelsPatientSupportTicket.prototype['emailAddress'] = undefined;

/**
 * @member {String} availability
 */
PpdSupportPortalModelsPatientSupportTicket.prototype['availability'] = undefined;

/**
 * @member {String} requireTranslation
 */
PpdSupportPortalModelsPatientSupportTicket.prototype['requireTranslation'] = undefined;

/**
 * @member {String} applicationName
 */
PpdSupportPortalModelsPatientSupportTicket.prototype['applicationName'] = undefined;

/**
 * @member {String} deviceOs
 */
PpdSupportPortalModelsPatientSupportTicket.prototype['deviceOs'] = undefined;

/**
 * @member {String} comment
 */
PpdSupportPortalModelsPatientSupportTicket.prototype['comment'] = undefined;






export default PpdSupportPortalModelsPatientSupportTicket;

