import {Box, CircularProgress, Container} from "@mui/material";
import React, {Component} from "react";

export class LoadingSpinner extends Component {

    render() {
        return (
            <Container
                style={{
                    border: "none",
                    minWidth: "100%",
                    height: "100vh",
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress sx={{position: "fixed", top: "25%"}} />
                </Box>
            </Container>
        );
    }
}
