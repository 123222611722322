import React from 'react';
import PatientPrivacyPolicy from "./Frontend/HelpComponents/PatientPrivacyPolicy";
import Globals from "../Globals";

export default function Footer() {

    const [open, setOpen] = React.useState(false);

    const handleOpenPatientPrivacyDialogClick = (e) => {
        e.preventDefault(); //prevents page from changing
        setOpen(true);
    }
    
    return (
        <>
            <PatientPrivacyPolicy 
                open={open}
                handleClose={() => setOpen(false)}
            />
            <div className="branded-footer">
                <div className="mobile-hide">
                    © {(new Date()).getFullYear()} Pharmaceutical Product Development, LLC.
                    All rights reserved.
                </div>
                <div className="mobile-show">
                    © {(new Date()).getFullYear()} PPD Inc. All rights reserved.
                </div>
                <div display="flex" alignItems="center" className="footer-links">
                    <div><a href="https://get.teamviewer.com/ppdremote" target="_blank">Team Viewer</a></div>
                    <div>|</div>
                    <div><a href="https://www.ppd.com/who-we-are/company-resources/privacy-policy/" target="_blank">Privacy Policy</a></div>
                    {Globals.instance.isPatient() && 
                        <React.Fragment>
                            <div>|</div>
                            <div><a href='#' onClick={handleOpenPatientPrivacyDialogClick}>Patient Privacy Note</a></div>
                        </React.Fragment>
                    }
                    {/*<div>|</div>*/}
                    {/*<div><Link to="/admin" underline="none">Admin Area</Link></div>*/}
                </div>
            </div>
        </>
    );
}
