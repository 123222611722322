import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import React from "react";
import {Form} from 'react-final-form';
import {PpdSupportPortalModelsAlert} from "../../../restapi";
import {TextInput} from "../../FormComponents/TextInput";
import {Required, StringRequired} from "../../helpers/validation";
import {SelectInput} from "../../FormComponents/SelectInput";
import {Datepicker} from "../../FormComponents/Datepicker";
import Deletion_AreYouSure from "../../helpers/Deletion_AreYouSure";
import FileField from "../../FormComponents/FileField";
import moment from 'moment';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const activeDataSet = [
    {
        value: true,
        label: 'Active',
    },
    {
        value: false,
        label: 'Inactive',
    },
];

const displayDataSet = [
    {
        value: "All",
        label: "All",
    },
    {
        value: "Patient",
        label: "Patient",
    },
    {
        value: "Clinical",
        label: "Clinical",
    },
];

const generateDropdownOptions = (dataSet): any[] => {
    let rtn = [];

    //rtn.push(<MenuItem key={0} value={0}>{'-- EITHER --'}</MenuItem>);

    Array.from(dataSet).forEach((option) => {
        rtn.push(<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>);
    });

    return rtn;
};

/* Take YYYY-MM-DD_00:00.00Z and convert it to shifted YYYY-MM-DD to match what's in the DatePicker */
const convertToUtcDate = (zDateTime): Date => {
    if (moment(zDateTime).isValid()) {
        let date = new Date(zDateTime);
        let userTimezoneOffset = date.getTimezoneOffset() * 60000;
        let adjustedDate = new Date(date.getTime() + Math.abs(userTimezoneOffset));
        return adjustedDate.toISOString().split('T')[0];
    }
};

export default function Alerts_Module_AddEdit(props) {

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const fileInput = React.createRef();
    
    const onSubmit = async (alertData): PpdSupportPortalModelsAlert => {

        let eFile = fileInput.current;

        alertData.startDate = convertToUtcDate(alertData.startDate);
        alertData.endDate = convertToUtcDate(alertData.endDate);

        if (!eFile.files || !eFile.files.length) {
            props.handleSubmit(alertData);
            return;
        }

        let filesize = ((eFile.files[0].size / 1024) / 1024).toFixed(4); // MB

        if (filesize > 5) { //5MB cap
            alert("File is too big!");
            return;
        }

        alertData.imageBase64 = await readFileAsync(eFile);
        alertData.image = eFile.files[0].name;
        props.handleSubmit(alertData);
    }

    const validate = (data): any => {
        
        let errors = {};

        let sd = moment(data.startDate);
        let ed = moment(data.endDate);

        if (sd.toDate() > ed.toDate()) {
            errors.startDate = 'Start Date must be less than or equal to End Date';
            errors.endDate = 'End Date must be greater than or equal to Start Date';
        }

        return errors;
    }
    
    const readFileAsync = (readFileInput) => { 
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(readFileInput.files[0]);
        })
    }
    
    return (
        <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={props.alertToEdit || {
                active: true,
                display: "All",
                startDate: new Date(),
                endDate: new Date(),
            }}
            render={({handleSubmit, submitting, pristine, invalid, errors, form}) => {
                return (
                    <Modal
                        open={props.open}
                        onClose={props.handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className="modal-form medium">
                            <Box display="flex" alignItems="center" >
                                <Box flexGrow={1}>
                                    <Typography id="modal-modal-title-not-upper" variant="h6" component="h2">
                                        Alert Details
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton onClick={props.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <form onSubmit={handleSubmit} noValidate autoComplete="off" >
                                <Grid container spacing={3} className="pt20">
                                    <Grid item xs={12} sm={12} md={4}>
                                        <SelectInput
                                            name="active"
                                            variant="outlined"
                                            fullWidth={true}
                                            shrink={true}
                                            displayLabel="Alert Status"
                                            options={generateDropdownOptions(activeDataSet)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <SelectInput
                                            name="display"
                                            variant="outlined"
                                            fullWidth={true}
                                            shrink={true}
                                            displayLabel="Display"
                                            options={generateDropdownOptions(displayDataSet)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}/>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Datepicker
                                            name="startDate"
                                            displayLabel="Start Date"
                                            required
                                            validate={Required("The Start Date is required")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Datepicker
                                            name="endDate"
                                            displayLabel="End Date"
                                            required
                                            validate={Required("The End Date is required")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}/>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextInput
                                            name="title"
                                            label="Title"
                                            variant="outlined"
                                            maxlength={255}
                                            required
                                            validate={StringRequired(1, 255, "Title required. May not exceed 255 characters.")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextInput
                                            name="description"
                                            label="Description"
                                            variant="outlined"
                                            rows={4}
                                            multiline
                                            maxlength={1000}
                                            required 
                                            validate={StringRequired(1, 1000, "Short Description may not exceed 1000 characters.")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FileField
                                            name={"image"}
                                            refProp={fileInput}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {props.alertToEdit?.image && props.alertToEdit?.image.length > 0 &&
                                            <>
                                                <h2>Current Alert Image</h2>
                                                <img
                                                    src={"/api/alert/" + props.alertToEdit?.key + "/" + props.alertToEdit?.image}
                                                    style={{maxWidth: 470, maxHeight: 250}}
                                                    alt="Currently Uploaded Image"
                                                />
                                            </>
                                        }
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12}>
                                        <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end">
                                            <Button size="large" variant="outlined" onClick={() => {setDeleteOpen(true)}}>Delete</Button>
                                            <Button size="large" variant="outlined" onClick={props.handleClose}>Cancel</Button>
                                            <Button size="large" variant="contained" onClick={handleSubmit} disabled={submitting || invalid}>Save</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                            <Deletion_AreYouSure 
                                open={deleteOpen}
                                handleClose={() => { 
                                    setDeleteOpen(false); 
                                }}
                                handleConfirm={() => {
                                    props.handleDelete();
                                    setDeleteOpen(false);
                                }}
                            />
                        </Box>
                    </Modal>
                );
            }}
        />
    );
}